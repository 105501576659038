

































import Vue from 'vue';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import VueLoading from '@/components/pages/Loding.vue';

import LocalDataService from '@/service/LocalDataService';
import { NurseryClassResponse } from 'chaild-api/lib';
import { ClassResponse } from '@/model/Response';

export default Vue.extend({
  components: {
    VueLoading,
  },
  data: () => {
    return {
      fetchLoading: false,
    };
  },
  computed: {
    allClass(): NurseryClassResponse[] | null {
      if (nurseryModule.allClasses) {
        // const myClass = this.myClass;
        // if (myClass) {
        //   const allClasses = nurseryModule.allClasses;
        //   const temp = allClasses.find(clas => clas.classId == myClass.classId);
        //   const index = allClasses.findIndex(clas => clas.classId == myClass.classId);
        //   allClasses.splice(index, 1);
        //   if (temp) {
        //     allClasses.unshift(temp);
        //   }
        //   return allClasses;
        // }
        return nurseryModule.allClasses;
      }
      return null;
    },
    myClass(): ClassResponse | null {
      const user = LocalDataService.getUser();
      if (user && user.nurserySchools[0].class) {
        return user.nurserySchools[0].class;
      }
      return null;
    },
  },

  methods: {
    toClassRecords(classId: string) {
      this.$router.push(`/record/class/${classId}`);
    },
  },

  async mounted() {
    this.fetchLoading = true;
    try {
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const nurseryId = nurserySchool.nurseryId;
        await nurseryModule.getNurseryClass(nurseryId);
      }
      this.$nextTick(() => {
        this.fetchLoading = false;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.fetchLoading = false;
    }
  },
});
