











































































































































































import LocalDataService from '@/service/LocalDataService';
import { chatModule } from '@/store/dataModules/chatModule';
import { ChatResponse, GroupChatResponse, MeResponse } from 'chaild-api/lib';
import VueLoading from '@/components/pages/Loding.vue';
import Vue from 'vue';
import { meModule } from '@/store/dataModules/meModule';

const dayOfWeekStrJP = ['日', '月', '火', '水', '木', '金', '土'];

export default Vue.extend({
  components: {
    VueLoading,
  },
  data: () => {
    return {
      dialog: false,
      checkbox: [],
      checkboxBulk: [],
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
      fetchLoading: false,
      btnSendLoading: false,
      btnRemoveLoading: false,
    };
  },
  computed: {
    chatReady(): ChatResponse[] {
      this.fetchLoading = true;
      const chatsReady = chatModule.chatsReady;
      if (chatsReady) {
        chatsReady.sort(function(a, b) {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        this.fetchLoading = false;
        return chatsReady;
      }
      this.fetchLoading = false;
      return [];
    },
    bulkChatsReady(): GroupChatResponse[] {
      this.fetchLoading = true;
      const bulkChatsReady = chatModule.bulkChatsReady;
      if (bulkChatsReady) {
        bulkChatsReady.sort(function(a, b) {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        this.fetchLoading = false;
        return bulkChatsReady;
      }
      this.fetchLoading = false;
      return [];
    },
    disabled(): Boolean {
      if (this.checkbox.length === 0 && this.checkboxBulk.length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    showLongText(body: string) {
      let replace = body.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
      const length = replace.length;
      if (length > 55) {
        replace = replace.slice(0, 55) + '...';
        return replace;
      }
      return body;
    },
    checkReadyMine(createdBy) {
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        if (createdBy.userId === nurserySchool.userId) {
          return true;
        } else {
          return false;
        }
      }
    },
    routerBack() {
      const routes = window.history.length;
      if (routes > 1) {
        this.$router.back();
      } else {
        this.$router.push(`/`);
      }
    },
    toChat(chat: ChatResponse) {
      this.$router.push(
        `/communication/chat/${chat.child.childId}?readyChatId=${chat.chatId}&text=${chat.body}&userId=${chat.createdBy?.userId}`
      );
    },
    toChatBulk(chat: GroupChatResponse) {
      this.$router.push(
        `/communication/new-message?readyBulkChatId=${chat.groupChatId}&userId=${chat.createdBy?.userId}`
      );
    },
    formatDate(date: string) {
      const temp = new Date(date);
      const week = dayOfWeekStrJP[temp.getDay()];
      const year = temp.getFullYear();
      const month = temp.getMonth() + 1;
      const day = temp.getDate();
      const formated = `${year}年${month}月${day}日(${week})`;
      return formated;
    },
    deleteChatReady: async function() {
      try {
        this.btnRemoveLoading = true;
        if (this.checkbox.length > 0) {
          const chatIds = this.checkbox.join(',');
          await chatModule.deleteChatReady(chatIds);
          // await chatModule.removeSendedChatReady(this.checkbox);
          this.init();
          this.snackbar = true;
          this.checkbox = [];
        }
        if (this.checkboxBulk.length > 0) {
          const groupChatId = this.checkboxBulk.join(',');
          await chatModule.deleteAllChatReady(groupChatId);
          // await chatModule.removeSendedBulkChatReady(this.checkboxBulk);
          this.init();
          this.snackbar = true;
          this.checkboxBulk = [];
        }
        this.snackBarText = '削除しました';
      } catch {
      } finally {
        this.btnRemoveLoading = false;
      }
    },
    sendChatReady: async function() {
      try {
        this.btnSendLoading = true;
        if (this.checkbox.length > 0) {
          await chatModule.sendChatReady(this.checkbox);
          await chatModule.removeSendedChatReady(this.checkbox);
          this.snackbar = true;
          this.checkbox = [];
        }
        if (this.checkboxBulk.length > 0) {
          const groupChatIds = this.checkboxBulk;
          await chatModule.sendAllChat({
            groupChatIds,
          });
          await chatModule.removeSendedBulkChatReady(this.checkboxBulk);
          this.snackbar = true;
          this.checkboxBulk = [];
        }
        this.snackBarText = '送信しました';
      } catch {
      } finally {
        this.btnSendLoading = false;
      }
    },
    async init() {
      this.fetchLoading = true;
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const nurseryId = nurserySchool.nurseryId;
        Promise.all([chatModule.fetchChatReady(nurseryId), chatModule.getAllChatReady(nurseryId)]);
      }
      this.$nextTick(() => {
        this.fetchLoading = false;
      });
    },
  },
  async mounted() {
    this.init();
  },
});
