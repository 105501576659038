







import Vue from 'vue';
import ClassDiaryContainer from '@/container/records/ClassDiaryContainer.vue';

export default Vue.extend({
  components: {
    ClassDiaryContainer,
  },
});
