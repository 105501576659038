import { GetCurrentVersion } from './../../model/Response';
import LocalDataService from '@/service/LocalDataService';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiMe from '@/api/ApiMe';

import {
  MultimediaAlbumListResponse,
  MultimediaAlbumResponse,
  MultimediaListResponse,
  MultimediaResponse,
} from 'chaild-api/lib/index';
import { PostMeRequest, PutMeRequest } from '@/model/Request';
import ApiMemoru from '@/api/ApiMemoru';

export interface MemoruAlbumState {
  album: MultimediaAlbumResponse | null;
  contents: MultimediaResponse[];
  count: number;
}

@Module({ dynamic: true, store, name: 'memoru-album', namespaced: true })
class MemoruAlbumModule extends VuexModule implements MemoruAlbumState {
  album: MultimediaAlbumResponse | null = null;
  contents: MultimediaResponse[] = [];
  count: number = -1;

  @Action({ rawError: true })
  public async getAlbum({ albumId }: { albumId: string | number }) {
    try {
      const response: MultimediaAlbumResponse = await ApiMemoru.getAlbum(albumId);
      if (response) {
        this.setAlbum(response);
      }
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async listAlbumContents({ albumId }: { albumId: string | number }) {
    try {
      const response: MultimediaListResponse = await ApiMemoru.listAlbumContents({
        albumId,
      });
      if (response) {
        this.setContents(response.items);
        this.setCount(response.total);
      }
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAlbum(album: MultimediaAlbumResponse | null) {
    this.album = album;
  }

  @Mutation
  public setContents(contents: MultimediaResponse[]) {
    this.contents = contents;
  }

  @Mutation
  public setCount(count: number) {
    this.count = count;
  }
}

export const memoruAlbumModule = getModule(MemoruAlbumModule);
