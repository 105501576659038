import LocalDataService from '@/service/LocalDataService';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

export interface Auth0State {
  auth0Client: Auth0Client | null;
}

@Module({ dynamic: true, store, name: 'auth0', namespaced: true })
class Auth0Module extends VuexModule implements Auth0State {
  public auth0Client: Auth0Client | null = null;

  @Action({ rawError: true })
  public async initClient() {
    if (
      process.env.VUE_APP_AUTH0_DOMAIN &&
      process.env.VUE_APP_AUTH0_CLIENT_ID
    ) {
      try {
        const client = await createAuth0Client({
          domain: process.env.VUE_APP_AUTH0_DOMAIN,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          redirect_uri: window.location.origin + '/signin',
          useRefreshTokens: true,
          cacheLocation: 'localstorage'
        });
        this.setClient(client);
      } catch (e) {
        if (e && e.error && e.error === 'interaction_required') {
        }
      }
    }
  }

  @Mutation
  public setClient(client: Auth0Client) {
    this.auth0Client = client;
  }

  @Action({ rawError: true })
  public async signinProcess() {
    console.log('auth', this.auth0Client)
    if (this.auth0Client) {
      await this.auth0Client.loginWithRedirect({
        redirect_uri: window.location.origin + '/signin',
      });
    }
  }

  @Action({ rawError: true })
  public async getAuth0User() {
    if (this.auth0Client && process.env.VUE_APP_AUTH0_DOMAIN) {
      const user = await this.auth0Client.getUser();
      return user;
    }
    return null;
  }

  @Action({ rawError: true })
  public async handleAuth0() {
    if (this.auth0Client && process.env.VUE_APP_AUTH0_DOMAIN) {
      await this.auth0Client.handleRedirectCallback();
      const user = await this.auth0Client.getUser();
      // const claim = await this.auth0Client.getIdTokenClaims();
      // const accessToken = claim.__raw;
      // LocalDataService.setAccessToken(accessToken);
      return user;
    }
    return null;
  }

  @Action({ rawError: true })
  public async isAuthenticated() {
    if (this.auth0Client) {
      const response = await this.auth0Client.isAuthenticated();
      return response;
    }
  }

  @Action({ rawError: true })
  public async getTokenSilently() {
    try {
      if (this.auth0Client) {
        const response = await this.auth0Client.getTokenSilently();
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async logout() {
    if (!this.auth0Client) {
      await this.initClient();
    }
    if (this.auth0Client) {
      await LocalDataService.removeItem();
      await this.auth0Client.logout();
    }
  }

  @Action({ rawError: true })
  public async getAuth0Token() {
    if (this.auth0Client) {
      const claims = await this.auth0Client.getIdTokenClaims();
      if (claims) {
        return claims.__raw;
      }
      await this.initClient();
      const claims2 = await this.auth0Client.getIdTokenClaims();
      if (claims2) {
        return claims2.__raw;
      }
      return '';
    } else {
      try {
        await this.initClient();
        const claims = await this.auth0Client!.getIdTokenClaims();
        if (claims) {
          return claims.__raw;
        } else {
          // this.logout();
        }
        return '';
      } catch (err) {
        return '';
      }
    }
  }
}

export const auth0Module = getModule(Auth0Module);
