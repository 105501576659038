import LocalDataService from '@/service/LocalDataService';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';


export interface CommonState {
    showLoader: boolean;
}

@Module({ dynamic: true, store, name: 'common', namespaced: true })
class CommonModule extends VuexModule implements CommonState {

    public showLoader: boolean = false;

    @Mutation
    public setLoader(showLoader: boolean) {
        this.showLoader = showLoader;
    }
}

export const commonModule = getModule(CommonModule);
