


















































import Vue from 'vue';
import VueLoading from '@/components/pages/Loding.vue';
import DatePager from '@/components/molecules/DatePager.vue';
import ChildDiary from '@/components/molecules/ChildDiary.vue';
import EatingRecord from '@/components/molecules/EatingRecord.vue';
import OtherRecord from '@/components/molecules/OtherRecord.vue';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import LocalDataService from '@/service/LocalDataService';
import { chatModule } from '@/store/dataModules/chatModule';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import {
  DiaryResponse,
  MeNurserySchoolResponse,
  MeResponse,
  NurseryAttendanceResponse,
  NurseryChildResponse,
  NurseryClassResponse,
} from 'chaild-api/lib';
import { childrenModule } from '@/store/dataModules/childrenModule';
import { ChildrenDiaryResponse, GetAttendanceSearchResponse, GetChildrenResponse } from '@/model/Response';
import { attendanceModule } from '@/store/dataModules/attendanceModule';
import { commonModule } from '@/store/viewModule/commonModule';
import { meModule } from '@/store/dataModules/meModule';
dayjs.locale(ja);

export default Vue.extend({
  components: {
    VueLoading,
    DatePager,
    ChildDiary,
    EatingRecord,
    OtherRecord,
  },

  data: () => {
    return {
      fetchLoading: false,
      date: '' as any,
      tabIndex: 0,
      classId: 0,
    };
  },
  computed: {
    clas(): NurseryClassResponse | null {
      if (nurseryModule.clas) {
        return nurseryModule.clas;
      }
      return null;
    },
    children(): NurseryChildResponse[] | null {
      if (childrenModule.children) {
        const children = childrenModule.children.children.filter(child => !(child.quitDate && child.quitDate < this.date.format('YYYY-MM-DD')) )
        return children;
      }
      return null;
    },
    absenceAttendance(): NurseryAttendanceResponse[] | null {
      if (attendanceModule.attendances && attendanceModule.attendances.attendances) {
        return attendanceModule.attendances.attendances;
      }
      return null;
    },
    showLoader(): boolean {
      if (commonModule.showLoader) {
        return commonModule.showLoader;
      }
      return false;
    },
    diaries(): DiaryResponse[] | null {
      if (childrenModule.diary && childrenModule.diary.diaries) {
        return childrenModule.diary.diaries;
      }
      return null;
    },
    me(): MeResponse | null {
      const me = LocalDataService.getUser();
      return me;
    },
    currentScool(): MeNurserySchoolResponse | undefined {
      if (this.me) {
        let nurserySchool = this.me.nurserySchools.find(nursery => nursery.isDefault === true);
        if (this.me.nurserySchools.length === 0) {
          return undefined
        }
        if (!nurserySchool) {
          nurserySchool = this.me.nurserySchools[0];
        }
        return nurserySchool;
      }
      return undefined;
    },
    features(): Array<{
      name: string; // 機能名
      slug: string; // 機能スラッグ
      isEnabled: boolean; // 機能有効フラグ
    }> {
      if (this.currentScool) {
        const messageFeature = this.currentScool.applicationPlan.nurseryMobile.featureGroups.find((fg) => fg.name === "記録");
        if (!messageFeature) {
          return [];
        }
        return messageFeature.features.filter((f) => f.isEnabled);
      }
      return [];
    },
    shouldShowRecords() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-record-report");
      if (find) {
        return true;
      }
      return false;
    },
    shouldShowDiary() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-record-diary");
      if (find) {
        return true;
      }
      return false;
    },
    shouldShowMeal() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-record-meal");
      if (find) {
        return true;
      }
      return false;
    },
    shouldShowClassDiary() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-record-class-diary");
      if (find) {
        return true;
      }
      return false;
    },
  },
  watch: {
    date: {
      handler() {
        this.init();
      },
      deep: true,
    },
    tabIndex: {
      handler () {
        LocalDataService.setRecordsTabIndex(this.tabIndex)
      }
    }
  },
  methods: {
    addDay() {
      this.date = this.date.add(1, 'd');
    },
    subtractDay() {
      this.date = this.date.subtract(1, 'd');
    },
    toClassDiary() {
      const classId = this.$route.params.classId;
      this.$router.push({
        path:`/record/class-diary/${classId}`,
        query: { date: `${this.date.toISOString()}` }
      });
    },
    async init() {
      this.fetchLoading = true;
      const user = LocalDataService.getUser();
      const classId = this.$route.params.classId;
      this.classId = parseInt(classId, 10);
      this.tabIndex = LocalDataService.getRecordsTabIndex()
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const nurseryId = nurserySchool.nurseryId;
        Promise.all([
          nurseryModule.getNurseryClassDetail({
            nurseryId,
            classId: this.classId,
          }),
          childrenModule.getChildren({
            classId: this.classId,
            nurseryId
          }),
          attendanceModule.searchAttendance({
            date: this.date.format('YYYY-MM-DD'),
            nurseryId,
            absence: 0,
            classId: this.classId,
          }),
          childrenModule.getChildDiary({
            classId: this.classId,
            date: this.date.format('YYYY-MM-DD'),
          })
        ]);
      }
      await this.$nextTick();
      this.fetchLoading = false;
    },
  },
  async created() {
    this.date = dayjs();
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    childrenModule.refreshChildren();
    meModule.setTabIndex('record')
  },
});
