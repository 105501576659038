
























import {
  DiaryResponse,
  NurseryAttendanceResponse,
  NurseryChildResponse,
  NurseryClassResponse,
} from 'chaild-api/lib';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    children: {
      type: Array as PropType<NurseryChildResponse[]>,
      required: true,
    },
    clas: {
      type: Object as PropType<NurseryClassResponse>,
      required: true,
    },
    absenceAttendance: {
      type: Array as PropType<NurseryAttendanceResponse[]>,
    },
    date: {
      type: Object as PropType<any>,
      required: true,
    },
    diaries: {
      type: Array as PropType<DiaryResponse[]>,
      required: true,
    },
  },
  computed: {},

  methods: {
    toClassChildrenRecords(child: NurseryChildResponse) {
      this.$router.push({
        path: `/record/class/${this.clas.classId}/child/${child.childId}`,
        query: { date: `${this.date.toISOString()}` }
        });
    },
    checkAbsence(child: NurseryChildResponse) {
      if (this.absenceAttendance) {
        this.absenceAttendance.find(attendance => attendance.child.childId === child.childId);
      }
    },
  },
});
