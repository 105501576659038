



























































































































































import LocalDataService from '@/service/LocalDataService';
import { chatModule } from '@/store/dataModules/chatModule';
import { ChatResponse, GroupChatResponse } from 'chaild-api/lib';
import VueLoading from '@/components/pages/Loding.vue';
import Vue from 'vue';

const dayOfWeekStrJP = ['日', '月', '火', '水', '木', '金', '土'];

export default Vue.extend({
  components: {
    VueLoading,
  },
  data: () => {
    return {
      fetchLoading: false,
      btnLoading: false,
      dialog: false,
      checkbox: [],
      checkboxBulk: [],
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
    };
  },
  computed: {
    chatsDraft(): ChatResponse[] {
      const chatsDraft = chatModule.chatsDraft;
      if (chatsDraft) {
        chatsDraft.sort(function(a, b) {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        return chatsDraft;
      }
      return [];
    },
    bulkChatsDraft(): GroupChatResponse[] {
      const bulkChatsDraft = chatModule.bulkChatsDraft;
      if (bulkChatsDraft) {
        bulkChatsDraft.sort(function(a, b) {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        return bulkChatsDraft;
      }
      return [];
    },
  },
  methods: {
    showLongText(body: string) {
      let replace = body.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
      const length = replace.length;
      if (length > 55) {
        replace = replace.slice(0, 55) + '...';
        return replace;
      }
      return body;
    },
    checkReadyMine(createdBy) {
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        if (createdBy.userId === nurserySchool.userId) {
          return true;
        } else {
          return false;
        }
      }
    },
    routerBack() {
      const routes = window.history.length;
      if (routes > 1) {
        this.$router.back();
      } else {
        this.$router.push(`/`);
      }
    },
    toChat(chat: ChatResponse) {
      this.$router.push(
        `/communication/chat/${chat.child.childId}?draftChatId=${chat.chatId}&text=${chat.body}&userId=${chat.createdBy?.userId}`
      );
    },
    toChatBulk(chat: GroupChatResponse) {
      this.$router.push(`/communication/new-message?draftBulkChatId=${chat.groupChatId}&userId=${chat.createdBy?.userId}`);
    },
    deleteChatDraft: async function() {
      try {
        this.btnLoading = true;
        if (this.checkbox.length > 0) {
          const chatIds = this.checkbox.join(',');
          await chatModule.deleteChatDraft(chatIds);
          this.init();
          this.snackbar = true;
          this.checkbox = [];
        }
        if (this.checkboxBulk.length > 0) {
          const groupChatId = this.checkboxBulk.join(',');
          await chatModule.deleteAllChatDraft(groupChatId);
          this.init();
          this.snackbar = true;
          this.checkboxBulk = [];
        }
        this.snackBarText = '削除しました';
      } catch {
      } finally {
        this.btnLoading = false;
      }
    },
    formatDate(date: string) {
      const temp = date.split('-');
      const weekTemp = new Date(parseInt(temp[0]), parseInt(temp[1]), parseInt(temp[2]));
      const week = dayOfWeekStrJP[weekTemp.getDay()];
      const formated = `${temp[0]}年${temp[1]}月${temp[2]}日(${week})`;
      return formated;
    },
    async init() {
      this.fetchLoading = true;
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const nurseryId = nurserySchool.nurseryId;
        Promise.all([chatModule.fetchChatDraft(nurseryId), chatModule.getAllChatDraft(nurseryId)]);
      }
      this.$nextTick(() => {
        this.fetchLoading = false;
      });
    },
  },
  async mounted() {
    this.init();
  },
});
