

































































































































































































import ChildOtherRecordListVue from '@/components/molecules/ChildOtherRecordList.vue';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';

import TemperatureModal from '@/components/molecules/TemperatureModal.vue';
import MilkModal from '@/components/molecules/MilkModal.vue';
import SleepModal from '@/components/molecules/SleepModal.vue';
import DefecationModal from '@/components/molecules/DefecationModal.vue';
import VueLoading from '@/components/pages/Loding.vue';

import {
  ChildResponse,
  NurseryChildResponse,
  NurseryClassResponse,
  NurseryDailyReportResponse,
  NurseryDefecationResponse,
  NurseryMilkResponse,
  NurseryTemperatureResponse,
  SleepResponse,
} from 'chaild-api/lib';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

import Vue, { PropType } from 'vue';
import { childrenModule } from '@/store/dataModules/childrenModule';
export default Vue.extend({
  components: {
    ChildOtherRecordListVue,
    TemperatureModal,
    MilkModal,
    SleepModal,
    DefecationModal,
    VueLoading,
  },
  props: {
    children: {
      type: Array as PropType<NurseryChildResponse[]>,
      required: true,
    },
    clas: {
      type: Object as PropType<NurseryClassResponse>,
      required: true,
    },
    date: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  data: () => {
    return {
      fetchLoading: false,
      preRecordedAt: '',
      recordedAt: '',
      from: '',
      to: '',
      state: '',
      temperature: 365 / 10,
      amountId: NaN,
      milkCategory: 'breast',
      isRecordDialog: false,
      isChangeRecordDialog: false,
      otherRecord: 'temperature',
      inputChildId: NaN,
      inputChild: null as ChildResponse | null,
      btnLoading: false,
      btnRemoveLoading: false,
      inputDefecation: null as NurseryDefecationResponse | null,
      inputTemperature: null as NurseryTemperatureResponse | null,
      inputMilk: null as NurseryMilkResponse | null,
      inputSleep: null as SleepResponse | null,
      // classOtherReport: [] as NurseryDailyReportResponse[],
    };
  },
  computed: {
    classOtherReport(): NurseryDailyReportResponse[] | null {
      if (diaryReportModule.classOtherReport) {
        return diaryReportModule.classOtherReport;
      }
      return null;
    },
    isSaveOtherRecord(): Boolean {
      if (this.recordedAt && this.state) {
        return true;
      } else if (this.recordedAt && this.amountId) {
        return true;
      } else if (this.recordedAt && this.temperature) {
        return true;
      } else if (this.from && this.to) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isChangeRecordDialog: {
      handler(val: boolean) {
        if (!val) {
          this.inputChildId = NaN;
          this.inputTemperature = null;
          this.inputDefecation = null;
          this.inputMilk = null;
          this.inputSleep = null;
        }
      },
      deep: true,
    },
    isRecordDialog: {
      handler(val: boolean) {
        if (!val) {
          this.inputChildId = NaN;
        }
      },
      deep: true,
    },
  },
  methods: {
    // 記録の保存（新規作成）
    async saveOtherRecord() {
      if (this.otherRecord === 'defecation') {
        try {
          this.btnLoading = true;
          await diaryReportModule.saveChildDefecation({
            childId: this.inputChildId,
            date: this.date.format('YYYY-MM-DD'),
            recordedAt: this.recordedAt,
            state: this.state,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.state = '';
        }
      } else if (this.otherRecord === 'temperature') {
        try {
          this.btnLoading = true;
          await diaryReportModule.saveChildTemperature({
            childId: this.inputChildId,
            date: this.date.format('YYYY-MM-DD'),
            recordedAt: this.recordedAt,
            temperature: this.temperature,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.temperature = 365 / 10;
        }
      } else if (this.otherRecord === 'milk') {
        try {
          this.btnLoading = true;
          await diaryReportModule.saveChildMilk({
            childId: this.inputChildId,
            date: this.date.format('YYYY-MM-DD'),
            recordedAt: this.recordedAt,
            amountId: this.amountId,
            milkCategory: this.milkCategory,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.amountId = NaN;
        }
      } else if (this.otherRecord === 'sleep') {
        try {
          this.btnLoading = true;
          await diaryReportModule.saveChildSleep({
            childId: this.inputChildId,
            date: this.date.format('YYYY-MM-DD'),
            from: this.from,
            to: this.to,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.from = '';
          this.to = '';
        }
      }
      this.preRecordedAt = '';
      this.isRecordDialog = false;
    },

    // 記録の変更
    async changeOtherRecord() {
      if (this.inputDefecation) {
        try {
          this.btnLoading = true;
          await diaryReportModule.updateChildDefecation({
            childId: this.inputChildId,
            defecationId: this.inputDefecation.defecationId,
            recordedAt: this.recordedAt,
            state: this.state,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.state = '';
          this.inputDefecation = null;
        }
      } else if (this.inputTemperature) {
        try {
          this.btnLoading = true;
          await diaryReportModule.updateChildTemperature({
            childId: this.inputChildId,
            temperatureId: this.inputTemperature.temperatureId,
            recordedAt: this.recordedAt,
            temperature: this.temperature,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.temperature = 365 / 10;
          this.inputTemperature = null;
        }
      } else if (this.inputMilk) {
        try {
          this.btnLoading = true;
          await diaryReportModule.updateChildMilk({
            childId: this.inputChildId,
            milkId: this.inputMilk.milkId,
            recordedAt: this.recordedAt,
            amountId: this.amountId,
            milkCategory: this.milkCategory,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.amountId = NaN;
          this.inputMilk = null;
        }
      } else if (this.inputSleep) {
        try {
          this.btnLoading = true;
          await diaryReportModule.updateChildSleep({
            sleepId: this.inputSleep.sleepId,
            from: this.from,
            to: this.to,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.amountId = NaN;
          this.inputSleep = null;
        }
      }
      this.isChangeRecordDialog = false;
    },

    // 記録の削除
    async removeRecord() {
      if (this.inputDefecation) {
        try {
          this.btnRemoveLoading = true;
          await diaryReportModule.deleteChildDefecation({
            childId: this.inputChildId,
            defecationId: this.inputDefecation.defecationId,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnRemoveLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.state = '';
          this.inputDefecation = null;
        }
      } else if (this.inputTemperature) {
        try {
          this.btnRemoveLoading = true;
          await diaryReportModule.deleteChildTemperature({
            childId: this.inputChildId,
            temperatureId: this.inputTemperature.temperatureId,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnRemoveLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.temperature = 365 / 10;
          this.inputTemperature = null;
        }
      } else if (this.inputMilk) {
        try {
          this.btnRemoveLoading = true;
          await diaryReportModule.deleteChildMilk({
            childId: this.inputChildId,
            milkId: this.inputMilk.milkId,
          });
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnRemoveLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.amountId = NaN;
          this.inputMilk = null;
        }
      }  else if (this.inputSleep) {
        try {
          this.btnRemoveLoading = true;
          await diaryReportModule.deleteChildSleep(this.inputSleep.sleepId);
          diaryReportModule.fetchChildOtherReport({
            classId: this.clas.classId.toString(),
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnRemoveLoading = false;
          this.inputChildId = NaN;
          this.recordedAt = '';
          this.amountId = NaN;
          this.inputSleep = null;
        }
      }
      this.isChangeRecordDialog = false;
    },

    changeRecordedAt(recordedAt: string) {
      this.preRecordedAt = recordedAt
      const recordedAtHour = recordedAt.split(':')[0];
      const recordedAtMinutes = recordedAt.split(':')[1];
      const recordedAtTime = dayjs()
        .hour(Number(recordedAtHour))
        .minute(Number(recordedAtMinutes));
      this.recordedAt = dayjs(recordedAtTime).toISOString();
    },
    changeFrom(recordedAt: string) {
      const recordedAtHour = recordedAt.split(':')[0];
      const recordedAtMinutes = recordedAt.split(':')[1];
      const recordedAtTime = dayjs()
        .hour(Number(recordedAtHour))
        .minute(Number(recordedAtMinutes));
      this.from = dayjs(recordedAtTime).toISOString();
    },
    changeTo(recordedAt: string) {
      const recordedAtHour = recordedAt.split(':')[0];
      const recordedAtMinutes = recordedAt.split(':')[1];
      const recordedAtTime = dayjs()
        .hour(Number(recordedAtHour))
        .minute(Number(recordedAtMinutes));
      this.to = dayjs(recordedAtTime).toISOString();
    },
    changeState(state: string) {
      this.state = state;
    },
    changeTemperature(temperature: number) {
      this.temperature = temperature;
    },
    changeAmount(amountId: number) {
      this.amountId = amountId;
    },
    changeMilkCategory(milkCategory: string) {
      this.milkCategory = milkCategory;
    },
    clickDialog(child: ChildResponse) {
      this.otherRecord = 'temperature'
      this.inputChild = child
      this.inputChildId = child.childId;
      this.isRecordDialog = true;
    },
    clickCancel() {
      this.isRecordDialog = false;
      this.isChangeRecordDialog = false;
      this.inputChildId = NaN;
      this.inputTemperature = null;
      this.inputDefecation = null;
      this.inputMilk = null;
      this.inputSleep = null;
    },
    changeDefecationModal(defecation: NurseryDefecationResponse, child: NurseryChildResponse) {
      console.log('changeDefecation', defecation);
      console.log('child', child);
      this.inputDefecation = defecation;
      this.inputChildId = child.childId;
      this.isChangeRecordDialog = true;
    },
    changeTemperatureModal(temperature: NurseryTemperatureResponse, child: NurseryChildResponse) {
      console.log('changeTemperature', temperature);
      console.log('child', child);
      this.inputTemperature = temperature;
      this.inputChildId = child.childId;
      this.isChangeRecordDialog = true;
    },
    changeMilkModal(milk: NurseryMilkResponse, child: NurseryChildResponse) {
      console.log('changeMilk', milk);
      console.log('child', child);
      this.inputMilk = milk;
      this.inputChildId = child.childId;
      this.isChangeRecordDialog = true;
    },
    changeSleepModal(sleep: SleepResponse, child: NurseryChildResponse) {
      console.log('changeSleep', sleep);
      console.log('child', child);
      this.inputSleep = sleep;
      this.inputChildId = child.childId;
      this.isChangeRecordDialog = true;
    },
    clickToParentsRecord(child: NurseryChildResponse) {
      this.$router.push({
        path: `/record/parents-record/class/${this.clas.classId}/child/${child.childId}`,
        query: { date: `${this.date.toISOString()}` }
      });
    }
  },
  mounted: async function() {
    this.fetchLoading = true;
    Promise.all([
      diaryReportModule.fetchChildOtherReport({
        classId: this.clas.classId.toString(),
        date: this.date.format('YYYY-MM-DD'),
      }),
      diaryReportModule.getChildMilkChoice()
    ]);
    this.$nextTick(() => {
      this.fetchLoading = false;
    });
    // if (diaryReportModule.classOtherReport) {
    //   console.log(diaryReportModule.classOtherReport);
    //   this.classOtherReport = diaryReportModule.classOtherReport;
    // }
  },
});
