import { render, staticRenderFns } from "./EatingStatusItem.vue?vue&type=template&id=4559e5df&scoped=true&"
import script from "./EatingStatusItem.vue?vue&type=script&lang=ts&"
export * from "./EatingStatusItem.vue?vue&type=script&lang=ts&"
import style0 from "./EatingStatusItem.vue?vue&type=style&index=0&id=4559e5df&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4559e5df",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VList,VListItem,VListItemTitle,VMenu})
