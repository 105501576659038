import { GetCurrentVersion } from './../../model/Response';
import LocalDataService from '@/service/LocalDataService';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiMe from '@/api/ApiMe';

import { MultimediaAlbumListResponse, MultimediaAlbumResponse } from 'chaild-api/lib/index';
import { PostMeRequest, PutMeRequest } from '@/model/Request';
import ApiMemoru from '@/api/ApiMemoru';

export interface MemoruAlbumsState {
  albums: MultimediaAlbumResponse[];
  total: number;
}

@Module({ dynamic: true, store, name: 'memoru-albums', namespaced: true })
class MemoruAlbumsModule extends VuexModule implements MemoruAlbumsState {
  albums: MultimediaAlbumResponse[] = [];
  total = 0;

  @Action({ rawError: true })
  public async listAlbums(nurseryId: number) {
    try {
      const response: MultimediaAlbumListResponse = await ApiMemoru.listAlbums({
        nurseryId,
      });
      if (response) {
        this.setAlbums(response);
        this.setTotal(response);
      }
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async listMoreAlbums(nurseryId: number) {
    const count = this.albums.length;
    try {
      const response: MultimediaAlbumListResponse = await ApiMemoru.listAlbums({
        nurseryId,
        skip: count,
      });
      if (response) {
        this.addAlbums(response);
        this.setTotal(response);
      }
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAlbums(albums: MultimediaAlbumListResponse) {
    this.albums = albums.items;
  }

  @Mutation
  public addAlbums(albums: MultimediaAlbumListResponse) {
    this.albums = this.albums.concat(albums.items);
  }

  @Mutation
  public setTotal(response: MultimediaAlbumListResponse) {
    this.total = response.total;
  }
}

export const memoruAlbumsModule = getModule(MemoruAlbumsModule);
