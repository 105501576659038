import { GetCurrentVersion } from './../model/Response';
import ApiClient from '@/api/ApiClient';
import {
  YearlyEventBulkResponseForNursery,
} from 'chaild-api/lib/index';

export default class ApiEvent {

  public static async fetchEvent(input: { nurseryId: number, from: string, to: string }): Promise<YearlyEventBulkResponseForNursery> {
    try {
      const response = await ApiClient.get(`/nursery/${input.nurseryId}/events`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
