












































































































































import Vue from 'vue';
import DatePager from '@/components/molecules/DatePager.vue';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import LocalDataService from '@/service/LocalDataService';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import { ClassDiaryResponse, NurseryClassResponse } from 'chaild-api/lib';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';
import { NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';
dayjs.locale(ja);

export default Vue.extend({
  components: {
    DatePager,
  },
  data: () => {
    return {
      date: '' as any,
      classId: 0,
      aim: '',
      activityAm: '',
      activityPm: '',
      assessment: '',
      support: '',
      appliedBy: '',
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
      btnApplyLoading: false,
      btnDraftLoading: false,
      classDiary: null as ClassDiaryResponse | null | any,
    };
  },
  computed: {
    clas(): NurseryClassResponse | null {
      if (nurseryModule.clas) {
        return nurseryModule.clas;
      }
      return null;
    },
    allStaff(): NurseryUserResponse[] {
      if (nurseryModule.allUser) {
        let allStaff = [] as any;
        nurseryModule.allUser.users.map(user => {
          allStaff.push({
            userId: user.userId,
            fullName: user.lastName + user.firstName,
          });
        });
        return allStaff;
      }
      return [];
    },
    checkStatus(): Boolean {
      if (this.classDiary) {
        if (this.classDiary.error === 'E014') {
          return false;
        } else if (this.classDiary.status === 'applied') {
          return true;
        } else {
          false;
        }
      }
      return false;
    },
    // classDiary(): ClassDiaryResponse | null {
    //   if (diaryReportModule.classDiary) {
    //     return diaryReportModule.classDiary
    //   }
    //   return null;
    // }
  },
  watch: {
    date: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    routerBack() {
      const routes = window.history.length
      if(routes > 1) {
        this.$router.back()
      } else {
        this.$router.push(`/record/class/${this.classId}`);
      }
    },
    addDay() {
      this.date = this.date.add(1, 'd');
    },
    subtractDay() {
      this.date = this.date.subtract(1, 'd');
    },
    saveDraft: async function() {
      if (!this.classDiary || this.classDiary.error === 'E014') {
        try {
          this.btnDraftLoading = true;
          const response = await diaryReportModule.createClassDiary({
            classId: this.classId,
            date: this.date.format('YYYY-MM-DD'),
            aim: this.aim,
            activityAm: this.activityAm,
            activityPm: this.activityPm,
            assessment: this.assessment,
            support: this.support,
            status: 'draft',
            appliedBy: this.appliedBy,
          });
          this.btnDraftLoading = false;
          this.classDiary = response;
          this.snackBarText = '下書き保存しました';
          this.snackbar = true;
        } catch (e) {
          console.log(e);
        } finally {
          this.btnDraftLoading = false;
        }
      } else if (this.classDiary) {
        try {
          this.btnDraftLoading = true;
          const response = await diaryReportModule.updateClassDiary({
            classDiaryId: this.classDiary.classDiaryId.toString(),
            aim: this.aim,
            activityAm: this.activityAm,
            activityPm: this.activityPm,
            assessment: this.assessment,
            support: this.support,
            status: 'draft',
            appliedBy: this.appliedBy,
          });
          this.btnDraftLoading = false;
          this.classDiary = response;
          this.snackBarText = '下書き保存しました';
          this.snackbar = true;
        } catch (e) {
          console.log(e);
        } finally {
          this.btnDraftLoading = false;
        }
      }
    },
    saveApply: async function() {
      if (!this.classDiary || this.classDiary.error === 'E014') {
        try {
          this.btnApplyLoading = true;
          const response = await diaryReportModule.createClassDiary({
            classId: this.classId,
            date: this.date.format('YYYY-MM-DD'),
            aim: this.aim,
            activityAm: this.activityAm,
            activityPm: this.activityPm,
            assessment: this.assessment,
            support: this.support,
            status: 'applied',
            appliedBy: this.appliedBy,
          });
          this.btnApplyLoading = false;
          this.classDiary = response;
          this.snackBarText = '申請しました';
          this.snackbar = true;
        } catch {}
      } else if (this.classDiary) {
        try {
          this.btnApplyLoading = true;
          const response = await diaryReportModule.updateClassDiary({
            classDiaryId: this.classDiary.classDiaryId.toString(),
            aim: this.aim,
            activityAm: this.activityAm,
            activityPm: this.activityPm,
            assessment: this.assessment,
            support: this.support,
            status: 'applied',
            appliedBy: this.appliedBy,
          });
          this.btnApplyLoading = false;
          this.classDiary = response;
          this.snackBarText = '申請しました';
          this.snackbar = true;
        } catch {}
      }
    },
    init: async function() {
      this.aim = '';
      this.activityAm = '';
      this.activityPm = '';
      this.assessment = '';
      this.support = '';
      const user = LocalDataService.getUser();
      const classId = this.$route.params.classId;
      this.classId = parseInt(classId, 10);
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const nurseryId = nurserySchool.nurseryId;
        await diaryReportModule.fetchClassDiary({
          classId: classId,
          date: this.date.format('YYYY-MM-DD'),
        });
        Promise.all([
          nurseryModule.getNurseryClassDetail({
            nurseryId,
            classId: this.classId,
          }),
          nurseryModule.getNurseryUser(nurseryId),
        ]);
        this.classDiary = await diaryReportModule.classDiary;
        if (this.classDiary) {
          if (
            this.classDiary.aim ||
            this.classDiary.activityAm ||
            this.classDiary.activityPm ||
            this.classDiary.assessment ||
            this.classDiary.support ||
            this.classDiary.createdBy
          ) {
            this.aim = this.classDiary.aim!;
            this.activityAm = this.classDiary.activityAm!;
            this.activityPm = this.classDiary.activityPm!;
            this.assessment = this.classDiary.assessment!;
            this.support = this.classDiary.support!;
            if (this.classDiary.status !== 'draft') {
              this.appliedBy = this.classDiary.createdBy.userNurseryId!;
            }
          }
        }
      }
    },
  },
  async created() {
    const date = this.$route.query.date as string;
    if (date) {
      const temp = dayjs(date);
      this.date = temp;
    } else {
      this.date = dayjs();
    }
  },
  async mounted() {
    this.init();
  },
  beforeDestroy() {
  },
});
