























import Vue, { PropType } from 'vue';
import ChildrenAttendanceList from '@/components/atoms/ChildrenAttendanceList.vue';
import { attendanceModule } from '@/store/dataModules/attendanceModule';
import { childrenModule } from '@/store/dataModules/childrenModule';
import VueLoading from '@/components/pages/Loding.vue';

import { ChatSummaryResponse, NurseryClassResponse } from 'chaild-api/lib/index';
import { GetAttendanceSearchResponse, GetChildrenResponse } from '@/model/Response';

import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';
import { chatModule } from '@/store/dataModules/chatModule';

export default Vue.extend({
  components: {
    VueLoading,
    ChildrenAttendanceList,
  },
  data: () => {
    return {
      attendanceClass: null as GetAttendanceSearchResponse | null,
      childrenClass: null as GetChildrenResponse | null,
      fetchLoading: false,
    };
  },
  props: {
    clas: {
      type: Object as PropType<NurseryClassResponse>,
      required: true,
    },
    myClass: {
      type: Object as PropType<any>,
    },
  },
  computed: {
    attendancesByClass(): GetAttendanceSearchResponse[] {
      return attendanceModule.attendancesByClass;
    },
    chatSummary() {
      if (chatModule.chatSummary) {
        const chatSummary = chatModule.chatSummary.summaries;
        const find = chatSummary.find(summary => summary.classId === this.clas.classId);
        if (find) {
          return find;
        }
      }
      return null;
    },
  },
  methods: {
    childAttendance(childId?: string) {
      if (childId) {
        console.log(childId);
        return childId;
      }
    },
  },
  async mounted() {
    this.fetchLoading = true;
    const today = dayjs().format('YYYY-MM-DD');
    const user = LocalDataService.getUser();
    if (user && today) {
      let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
      if (!nurserySchool) {
        nurserySchool = user.nurserySchools[0];
      }
      const nurseryId = nurserySchool.nurseryId;
      this.attendanceClass = await attendanceModule.searchAttendanceByClass({
        date: today,
        nurseryId: nurseryId,
        classId: this.clas.classId,
      });
      this.childrenClass = await childrenModule.getChildren({
        classId: this.clas.classId,
        nurseryId
      });
    }
    await this.$nextTick();
    this.fetchLoading = false;
  },
});
