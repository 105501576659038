






























import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';
import { NurseryChildResponse, NurseryTemperatureResponse } from 'chaild-api/lib';
import { chatModule } from '@/store/dataModules/chatModule';

// model
// import { VForm } from '@/model/vuetify/VForm';

export default Vue.extend({
  name: 'MemoForm',

  props: {
    child: {
      type: Object as PropType<NurseryChildResponse>,
      default: null,
    },
    date: {
      type: Object as PropType<any>,
      default: {},
    },
    comment: {
      type: Array as PropType<string[]>,
      default: [],
    },
  },

  computed: {},

  data: () => ({
    inputComment: '',
    commentLoading: false,
  }),

  methods: {
    async saveComment() {
      if (this.inputComment && this.child) {
        try {
          this.commentLoading = true;
          await chatModule.saveComment({
            childId: this.child.childId,
            date: this.date.format('YYYY-MM-DD'),
            comment: this.inputComment,
          });
          this.$emit('saveComment', 'コメント保存しました');
        } catch {
        } finally {
          this.commentLoading = false;
        }
      }
    },
  },
  mounted() {
    if (this.comment.length > 0) {
      this.inputComment = this.comment[0];
    }
  },
});
