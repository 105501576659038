





















import Vue, { PropType } from 'vue';
import EatingStatus from '@/components/molecules/EatingStatus.vue';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';
import VueLoading from '@/components/pages/Loding.vue';
import {
  MealResponse,
  NurseryChildResponse,
  NurseryClassResponse,
  MealStatus,
  UpdateMealRequest,
} from 'chaild-api/lib';
import { commonModule } from '@/store/viewModule/commonModule';
export default Vue.extend({
  components: {
    EatingStatus,
    VueLoading,
  },
  props: {
    children: {
      type: Array as PropType<NurseryChildResponse[]>,
      required: true,
    },
    clas: {
      type: Object as PropType<NurseryClassResponse>,
      required: true,
    },
    date: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  data: () => {
    return {
      btnLoading: false,
      fullBtnLoading: false,
      fetchLoading: false,
    };
  },
  computed: {
    classMealReport(): MealResponse[] | null {
      if (diaryReportModule.classMeal) {
        return diaryReportModule.classMeal;
      }
      return null;
    },
  },
  methods: {
    async changeStatusItem(input: UpdateMealRequest) {
      try {
        this.btnLoading = true;
        await diaryReportModule.updateClassMeal(input);
        await diaryReportModule.fetchClassMeal({
          classId: this.clas.classId.toString(),
          date: this.date.format('YYYY-MM-DD'),
        });
      } catch {
      } finally {
        this.btnLoading = false;
      }
    },
    async createMealReport(childId: number) {
      try {
        commonModule.setLoader(true)
        await diaryReportModule.createClassMeal({
          childId,
          date: this.date.format('YYYY-MM-DD'),
          stapleFood: MealStatus.notServed,
          mainDish: MealStatus.notServed,
          sideDish: MealStatus.notServed,
          dessert: MealStatus.notServed,
          soup: MealStatus.notServed,
          milk: MealStatus.notServed,
          snack: MealStatus.notServed,
          comment: '',
        });
        await diaryReportModule.fetchClassMeal({
          classId: this.clas.classId.toString(),
          date: this.date.format('YYYY-MM-DD'),
        });
      } catch {
      } finally {
        commonModule.setLoader(false)
      }
    },
    async fullEat(meal: MealResponse) {
      try {
        this.fullBtnLoading = true;
        commonModule.setLoader(true);
        await diaryReportModule.updateClassMeal({
          childId: meal.childId,
          mealId: meal.mealId,
          stapleFood: MealStatus.finished,
          mainDish: MealStatus.finished,
          sideDish: MealStatus.finished,
          dessert: MealStatus.finished,
          soup: MealStatus.finished,
          milk: MealStatus.finished,
          snack: MealStatus.notServed,
        });
        await diaryReportModule.fetchClassMeal({
          classId: this.clas.classId.toString(),
          date: this.date.format('YYYY-MM-DD'),
        });
      } catch {
      } finally {
        this.fullBtnLoading = false;
        commonModule.setLoader(false);
      }
    },
  },
  mounted: async function() {
    this.fetchLoading = true;
    Promise.all([
      await diaryReportModule.fetchClassMeal({
        classId: this.clas.classId.toString(),
        date: this.date.format('YYYY-MM-DD'),
      }),
    ]);
    this.$nextTick(() => {
      this.fetchLoading = false;
    });
  },
});
