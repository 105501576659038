import { chatModule } from './chatModule';
import { auth0Module } from './auth0Module';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiPusher from '@/api/ApiPusher';
import {
  PrivateChannelAuthResponse,
  PushAuthResponse,
} from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
import Pusher from 'pusher-js';

export interface PusherState {
}

const apiKey = process.env.VUE_APP_PUSHER_APP_KEY;
const url = process.env.VUE_APP_API_URL;
const instanceId = process.env.VUE_APP_BEAMS_INSTANCE_ID;

@Module({ dynamic: true, store, name: 'pusher', namespaced: true })
class PusherModule extends VuexModule implements PusherState {

  @Action
  public async subscribe() {
    // Pusher.logToConsole = true; // 👈 Enable pusher logging - don't include this in production
    const user = LocalDataService.getUser();
    if (user) {
      let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
      if (!nurserySchool) {
        nurserySchool = user.nurserySchools[0];
      }
      const userId = nurserySchool.userId;
      const authorizer = (ch, options) => {
        return {
          authorize: (socketId, callback) => {
            ApiPusher
              .publishPrivateChanel({
                socketId,
                user_id: userId,
              })
              .then(res => {
                return res;
              })
              .then(data => {
                callback(null, data);
              })
              .catch(err => {
                callback(new Error(`Error calling auth endpoint: ${err}`), {
                  auth: '',
                });
              });
          },
        };
      };

      const accessToken = await auth0Module.getAuth0Token();
      if (apiKey && accessToken) {
        const pusher: any = new Pusher(apiKey, {
          cluster: 'ap3',
          authorizer,
          authEndpoint: url + '/private-channel/auth',
          // auth: {
          //   headers: {
          //     Authorization: `Bearer ${accessToken}`,
          //     'Content-Type': 'application/json; charset=utf-8',
          //     'x-api-key': apiKey,
          //   },
          //   params: {
          //     user_id: userId
          //   }
          // },
        });
        // console.log('STATE', await pusher.connection.state)
        const channel = await pusher.subscribe(`private-nursery-${userId}`);
        console.log('PUSHER channel', channel)
        channel.bind('pusher:subscription_succeeded', data => {
          console.log('pusher:subscription_succeeded', data);
        });

        // チャットを受け取ったとき
        channel.bind('chatReceived', async function (data) {
          console.log('chatReceived', data)
          await chatModule.addChat(data)
        });


        // 欠席連絡が報告されたとき
        channel.bind('absentReported', async function (data) {
          await chatModule.addChat(data)
          console.log('absentReported', data)
          const user = LocalDataService.getUser();
          if (user) {
            let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
            if (!nurserySchool) {
              nurserySchool = user.nurserySchools[0];
            }
            const nurseryId = nurserySchool.nurseryId;
            await chatModule.getChatSummary({
              nurseryId,
              date: data.date
            });
          }
        });

        // 送迎変更が報告されたとき
        channel.bind('pickupReported', async function (data) {
          await chatModule.addChat(data)
          console.log('absentReported', data)
          const user = LocalDataService.getUser();
          if (user) {
            let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
            if (!nurserySchool) {
              nurserySchool = user.nurserySchools[0];
            }
            const nurseryId = nurserySchool.nurseryId;
            await chatModule.getChatSummary({
              nurseryId,
              date: data.date
            });
          }
        });

        // 新しい申請を受信したとき
        channel.bind('workflowCreated', async function (data) {
          console.log('workflowCreated', data)
        });

        // 申請が承認されたとき
        channel.bind('workflowApproved', async function (data) {
          console.log('workflowApproved', data)
        });

        // 申請が却下されたとき
        channel.bind('workflowRejected', async function (data) {
          console.log('workflowRejected', data)
        });
      }
    }
  }
}


export const pusherModule = getModule(PusherModule);
