<template>
    <div class="pull-down-container">
        <div class="pull-down-header" v-bind:style="{'height': pullDown.height + 'px'}">
            <div class="pull-down-content" :style="pullDownContentStyle">
                <i class="pull-down-content--icon" v-bind:class="iconClass"></i>
                <!--<span class="pull-down-content--label">{{label}}</span> -->
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    // プルダウンの状態
    const STATUS_ERROR = -1;
    const STATUS_START = 0;
    const STATUS_READY = 1;
    const STATUS_REFRESH = 2;
    // プルダウンのラベル
    const LABELS = ['エラー', '更新', '更新可', '更新中...'];
    const ANIMATION = 'height .2s ease';

    export default {
        props: {
            onRefresh: {
                type: Function
            },
            config: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },
        data() {
            return {
                pullDown: {
                    status: 0,
                    height: 0,
                    msg: ''
                },
                canPull: false,
            };
        },
        computed: {
            label() {
                // プルダウンのラベル
                if (this.pullDown.status === STATUS_ERROR) {
                    return this.pullDown.msg;
                }
                return this.customLabels[this.pullDown.status + 1];
            },
            customLabels() {
                let errorLabel = this.config.errorLabel !== undefined ? this.config.errorLabel : LABELS[0];
                let startLabel = this.config.startLabel !== undefined ? this.config.startLabel : LABELS[1];
                let readyLaebl = this.config.readyLabel !== undefined ? this.config.readyLabel : LABELS[2];
                let loadingLabel = this.config.loadingLabel !== undefined ? this.config.loadingLabel : LABELS[3];
                return [errorLabel, startLabel, readyLaebl, loadingLabel];
            },
            iconClass() {
                // プルダウンのアイコン
                if (this.pullDown.status === STATUS_REFRESH) {
                    return 'pull-down-refresh';
                } else if (this.pullDown.status === STATUS_ERROR) {
                    return 'pull-down-error';
                }
                return '';
            },
            pullDownContentStyle() {
                return {
                    bottom: (this.config.pullDownHeight - 40) / 2 + 'px'
                };
            }
        },
        mounted() {
            this.$nextTick(() => {
                var el = this.$el;
                var pullDownHeader = el.querySelector('.pull-down-header');
                var icon = pullDownHeader.querySelector('.pull-down-content--icon');
                // デフォルトのプルダウン高さを設定する
                this.config.pullDownHeight = this.config.pullDownHeight || 40;
                /**
                 * プルダウンのステータスをリセットする
                 * @param {Object} pullDown 
                 * @param {Boolean} withAnimation //プルアップ時にアニメーションを追加するかどうか
                 */
                let resetPullDown = (pullDown, withAnimation) => {
                    if (withAnimation) {
                        pullDownHeader.style.transition = ANIMATION;
                    }
                    pullDown.height = 0;
                    pullDown.status = STATUS_START;
                };
                // 開始位置と距離を含むタッチ位置の保存
                var touchPosition = {
                    start: 0,
                    distance: 0
                };

                // オプション オブジェクトのゲッターを介してテストして、パッシブ プロパティがアクセスされているかどうかを確認
                var supportsPassive = false;
                try {
                    var opts = Object.defineProperty({}, 'passive', {
                        get: function() {
                            supportsPassive = true;
                        }
                    });
                    /* global window */
                    window.addEventListener("test", null, opts);
                } catch (e) {}

                // touchstart イベントをバインドしてタッチの開始位置を保存
                el.addEventListener('touchstart', e => {
                    if (window.scrollY === 0) {
                        this.canPull = true;
                    } else {
                        this.canPull = false;
                    }
                    touchPosition.start = e.touches.item(0).pageY;
                }, supportsPassive ? {passive: true} : false);

                /**
                 * touchmove イベントをバインドするには、次の手順を実行
                 * まず、プルダウンの高さを更新
                 * 最後に、距離に基づいてプルダウンのステータスを更新
                 */
                el.addEventListener('touchmove', e => {
                    if (!this.canPull) {
                        return;
                    }

                    var distance = e.touches.item(0).pageY - touchPosition.start;
                    // プルダウンの高さを 50 に制限します
                    distance = distance > 50 ? 50 : distance;
                    // ネイティブスクロールを防ぐ
                    if (distance > 0) {
                        el.style.overflow = 'hidden';
                    }
                    // touchPositionとプルダウンの高さを更新
                    touchPosition.distance = distance;
                    this.pullDown.height = distance;
                    /**
                     * 距離がプルダウンの高さより大きい場合
                     * プルダウンのステータスを STATUS_READY に設定
                     */
                    if (distance > this.config.pullDownHeight) {
                        this.pullDown.status = STATUS_READY;
                        icon.style.transform = 'rotate(180deg)';
                    } else {
                        /*
                         *それ以外の場合は、プルダウンのステータスを STATUS_START に設定
                         * 距離に基づいてアイコンを回転
                         */
                        this.pullDown.status = STATUS_START;
                        icon.style.transform = 'rotate(' + distance / this.config.pullDownHeight * 180 + 'deg)';
                    }
                }, supportsPassive ? {passive: true} : false);

                // バインドタッチエンドイベント
                el.addEventListener('touchend', e => {
                    this.canPull = false;
                    el.style.overflowY = 'auto';
                    pullDownHeader.style.transition = ANIMATION;
                    // リセットアイコン回転
                    icon.style.transform = '';
                    // 距離が60より大きい場合
                    if (touchPosition.distance - el.scrollTop > this.config.pullDownHeight) {
                        el.scrollTop = 0;
                        this.pullDown.height = this.config.pullDownHeight;
                        this.pullDown.status = STATUS_REFRESH;
                        // リフレッシュコールバックをトリガーする
                        if (this.onRefresh && typeof this.onRefresh === 'function') {
                            var res = this.onRefresh();
                            // onRefresh の場合は Promise を返す
                            if (res && res.then && typeof res.then === 'function') {
                                res.then(result => {
                                    resetPullDown(this.pullDown, true);
                                }, error => {
                                    // エラーを表示し、1秒後にプルダウンを非表示
                                    if (typeof error !== 'string') {
                                        error = false;
                                    }
                                    this.pullDown.msg = error || this.customLabels[0];
                                    this.pullDown.status = STATUS_ERROR;
                                    setTimeout(() => {
                                        resetPullDown(this.pullDown, true);
                                    }, 1000);
                                });
                            } else {
                                resetPullDown(this.pullDown);
                            }
                        } else {
                            resetPullDown(this.pullDown);
                        }
                    } else {
                        resetPullDown(this.pullDown);
                    }
                    // タッチ位置をリセット
                    touchPosition.distance = 0;
                    touchPosition.start = 0;
                });
                // トランジション終了時にトランジションを削除
                pullDownHeader.addEventListener('transitionend', () => {
                    pullDownHeader.style.transition = '';
                });
                pullDownHeader.addEventListener('webkitTransitionEnd', () => {
                    pullDownHeader.style.transition = '';
                });
            });
        }
    };
</script>

<style lang="sass">
    .pull-down-container 
        height: 100%
        max-height: 100%
        overflow-y: auto
    
    .pull-down-header 
        width: 100%
        height: 0px
        overflow: hidden
        position: relative
        background-color: #fff
    
    .pull-down-content 
        position: absolute
        max-width: 90%
        bottom: 10px
        left: 50%
        transform: translateX(-50%)
        height: 40px
        color: #808080
        text-align: center
        border-left: 20px solid transparent
        font-family: "noto-thin", "Helvetica Neue", Helvetica, Arial, sans-serif
        font-size: 14px
        &--icon 
            float: left
            height: 20px
            width: 20px
            margin-top: 10px
            margin-left: -20px
            background: url(./down-arrow.png) no-repeat center center
            background-size: 20px 20px
            &.pull-down-refresh 
                background: url(./refresh-icon.png) no-repeat center center
                background-size: 20px 20px
                animation: rotate 2s infinite
                animation-timing-function: linear
            
            &.pull-down-error 
                background: url(./error-icon.png) no-repeat center center
                background-size: 20px 20px
            
        
        &--label 
            float: left
            height: 20px
            line-height: 20px
            margin-left: 10px
            margin-top: 10px
        
    
    @keyframes rotate 
        from 
            transform: rotate(0deg)
        
        to 
            transform: rotate(360deg)
        
    
</style>