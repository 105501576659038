





import Vue from 'vue';
import CommunicationReadySendContainer from '@/container/communication/CommunicationReadySendContainer.vue';

export default Vue.extend({
  components: {
    CommunicationReadySendContainer,
  },
});
