













import Vue from 'vue';
// import JwtDecode from 'jwt-decode';
import LocalDataService from '@/service/LocalDataService';
import { auth0Module } from '@/store/dataModules/auth0Module';
import { meModule } from '@/store/dataModules/meModule';

export default Vue.extend({
  methods: {
    signIn() {
      // auth0Module.signinProcess();
      meModule.fetchMe();
    },
    logout() {
      // auth0Module.signinProcess();
      auth0Module.logout();
    },
  },
  async mounted() {
    // const code = this.$route.query.code;
    // const state = this.$route.query.state;
    // if (code && state) {
    //   this.$router.push('/');
    // }
  },
});
