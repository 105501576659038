import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiNursery from '@/api/ApiNursery';

import { NurseryClassResponse } from 'chaild-api/lib/index';
import {
  NurseryUserListResponse,
  NurseryUserResponse,
} from 'chaild-api/src/component/nursery/user/types';

export interface NurseryState {
  allClasses: NurseryClassResponse[] | null;
  allUser: NurseryUserListResponse | null;
  clas: NurseryClassResponse | null;
}

@Module({ dynamic: true, store, name: 'nursery', namespaced: true })
class NurseryModule extends VuexModule implements NurseryState {
  public allClasses: NurseryClassResponse[] | null = null;
  public allUser: NurseryUserListResponse | null = null;
  public clas: NurseryClassResponse | null = null;

  @Action({ rawError: true })
  public async getNurseryUser(nurseryId: number) {
    try {
      const response = await ApiNursery.getNurseryUser(nurseryId);
      this.setAllUser(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAllUser(allUser: NurseryUserListResponse) {
    this.allUser = allUser;
  }

  @Action({ rawError: true })
  public async getNurseryClass(nurseryId: number) {
    try {
      const response = await ApiNursery.getNurseryClass(nurseryId);
      this.setAllClasses(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async getNurseryClassWithYear({ nurseryId, year }: { nurseryId: number; year: number }) {
    try {
      const response = await ApiNursery.getNurseryClass(nurseryId, year);
      this.setAllClasses(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAllClasses(allClasses: NurseryClassResponse[]) {
    this.allClasses = allClasses;
  }

  @Action({ rawError: true })
  public async getNurseryClassDetail(input: { nurseryId: number; classId: number }) {
    try {
      const response = await ApiNursery.getNurseryClassDetail(input);
      this.setClass(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setClass(clas: NurseryClassResponse) {
    this.clas = clas;
  }

  @Mutation
  public refreshClass() {
    this.clas = null;
  }
}
export const nurseryModule = getModule(NurseryModule);
