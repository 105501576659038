






































































































import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import { chatModule } from '@/store/dataModules/chatModule';
import { AbsenceReason, AbsenceReasonType } from 'chaild-api/lib/index';

// model
import { VForm } from '@/model/Vuetify/VForm';
import LocalDataService from '@/service/LocalDataService';

interface DataType {
  dates: string[];
  startedAt: string;
  endedAt: string;
  reason: string;
  infectionReason: string;
  comment: string | null;
  fetchLoading: boolean;
  isDatePicker: boolean;
  btnLoading: boolean;
  choices: any;
  infectionChoices: any;
}

export default Vue.extend({
  name: 'AbsenceModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    date: {
      type: Object as PropType<any>,
      default: {},
    },
    nurseryId: {
      type: Number,
      default: NaN,
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form(): VForm {
      return (this.$refs as any).post_absence_form;
    },
    allowMinDate(): string {
      return dayjs().format('YYYY-MM-DD');
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    infectionRules() {
      if (this.reason === 'infection' && !this.infectionReason) {
        return '必須入力項目です';
      } else {
        return true;
      }
    },
  },

  watch: {
    isOpen: {
      async handler(val: boolean) {
        if (val) {
          this.dates = [];
          this.startedAt = '';
          this.endedAt = '';
          this.reason = '';
          this.comment = null;
        }
      },
      deep: true,
    },
  },

  data: (): DataType => ({
    dates: [],
    startedAt: '',
    endedAt: '',
    reason: '',
    infectionReason: '',
    comment: '',
    fetchLoading: false,
    isDatePicker: false,
    btnLoading: false,
    choices: [
      {
        text: '発熱',
        value: AbsenceReason.fever,
      },
      {
        text: '家庭の事情',
        value: AbsenceReason.personal,
      },
      {
        text: '感染症',
        value: 'infection',
      },
      {
        text: 'その他（コメントの入力が必要）',
        value: AbsenceReason.others,
      },
    ],
    infectionChoices: [
      {
        text: '新型コロナウイルス',
        value: AbsenceReason.covid19,
      },
      {
        text: '溶連菌感染症',
        value: AbsenceReason.infStrep,
      },
      {
        text: 'マイコプラズマ肺炎',
        value: AbsenceReason.infMycoplasma,
      },
      {
        text: '手足口病',
        value: AbsenceReason.infHFMD,
      },
      {
        text: '伝染性紅斑（りんご病）',
        value: AbsenceReason.infSlappedCheek,
      },
      {
        text: 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）',
        value: AbsenceReason.infStomach,
      },
      {
        text: 'ヘルパンギーナ',
        value: AbsenceReason.infHerpangina,
      },
      {
        text: 'RSウィルス感染症',
        value: AbsenceReason.infRS,
      },
      {
        text: '帯状疱疹',
        value: AbsenceReason.infZoster,
      },
      {
        text: '突発性発しん',
        value: AbsenceReason.infRoseola,
      },
      {
        text: '伝染性膿痂疹（とびひ）',
        value: AbsenceReason.infImpetigo,
      },
    ],
  }),

  methods: {
    close() {
      this.$emit('close');
    },
    async clickPost() {
      if (this.form.validate()) {
        if (this.dates.length === 1) {
          this.startedAt = this.dates[0];
          this.endedAt = this.dates[0];
        } else {
          const d1 = dayjs(this.dates[0]);
          const d2 = dayjs(this.dates[1]);
          if (d1.isAfter(d2)) {
            this.startedAt = this.dates[1];
            this.endedAt = this.dates[0];
          } else {
            this.startedAt = this.dates[0];
            this.endedAt = this.dates[1];
          }
        }
        this.btnLoading = true;
        if (!this.comment) {
          this.comment = null;
        }
        try {
          if (this.reason === 'infection') {
            await chatModule.postChatsAbsence({
              childId: this.childId,
              startedAt: this.startedAt,
              endedAt: this.endedAt,
              reason: this.infectionReason,
            });
          } else {
            await chatModule.postChatsAbsence({
              childId: this.childId,
              startedAt: this.startedAt,
              endedAt: this.endedAt,
              reason: this.reason,
              comment: this.comment,
            });
          }

          this.$emit('posted');
          this.$emit('close');
          this.btnLoading = false;
        } catch (error) {
          this.$emit('close');
          this.btnLoading = false;
        }
      }
    },
  },

  async mounted() {
    this.fetchLoading = true;
    await chatModule.getChatsAbsence(this.childId),
      this.$nextTick(() => {
        this.fetchLoading = false;
      });
  },
});
