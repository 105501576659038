
/* tslint:disable:max-classes-per-file */
import { Authorizer } from 'chaild-api/src/common/entity/aws/authorize';
import { ChatSummaryResponse, MeResponse } from 'chaild-api/lib/index';

const KEY_ACCESS_TOKEN = 'CHAILD_ACCESS_TOKEN';
const KEY_AUTHORIZER = 'CHAILD_AUTHORIZER';
const KEY_PUSH_AUTHORIZER = 'CHAILD_PUSH_AUTHORIZER';
const KEY_USER = 'CHAILD_USER';
const KEY_CHAT_TEXT = 'CHAILD_CHAT_TEXT';
const KEY_CHAT_CHAILDID = 'CHAILD_CHAT_CHAILDID';
const KEY_BULK_CHAT_TEXT = 'CHAILD_BULK_CHAT_TEXT';
const KEY_BULK_CHAT_ADDRESS = 'CHAILD_BULK_CHAT_ADDRESS';
const KEY_DIARY_TEXT = 'CHAILD_DIARY_TEXT';
const KEY_CLASS_DIARY_TEXT = 'CHAILD_CLASS_DIARY_TEXT';
const KEY_VERSION = 'CHAILD_VERSION';
const KEY_CHAT_SUMMARY = 'CHAILD_CHAT_SUMMARY';
const KEY_RECORDS_TAB_INDEX = 'CHAILD_RECORDS_TAB_INDEX';

class LocalAuthorizer implements Authorizer {
  public given_name?: string;
  public family_name?: string;
  public nickname?: string;
  public name?: string;
  public picture?: string;
  public locale?: string;
  public updated_at: string = '';
  public email: string = '';
  public email_verified?: boolean;
  public exception?: string | undefined;
  public iss: string = '';
  public sub: string = '';
  public aud: string = '';
  public iat: number = 0;
  public exp: number = 0;
  public at_hash: string = '';
  public nonce: string = '';
  public claims: Authorizer | undefined;
  public chatSummary: ChatSummaryResponse | undefined;
}

export default class LocalDataService {
  // バージョン管理

  public static setVersion(text: string) {
    localStorage.setItem(`${KEY_VERSION}`, text);
    return text;
  }

  public static getVersion(): string | null {
    const saved = localStorage.getItem(`${KEY_VERSION}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  // チャットのサマリ
  public static setChatSummary(summary: ChatSummaryResponse) {
    localStorage.setItem(`${KEY_CHAT_SUMMARY}`, JSON.stringify(summary));
    return summary;
  }

  public static getChatSummary(): ChatSummaryResponse | null {
    const saved = localStorage.getItem(`${KEY_CHAT_SUMMARY}`);
    if (saved) {
      const chatSummary = JSON.parse(saved) as ChatSummaryResponse;
      return chatSummary;
    } else {
      return null;
    }
  }

  // rhyme push通知用

  public static setPushAuthorizer(authorizer: string) {
    localStorage.setItem(`${KEY_PUSH_AUTHORIZER}`, authorizer);
    return authorizer;
  }

  public static getPushAuthorizer(): string | null {
    const saved = localStorage.getItem(`${KEY_PUSH_AUTHORIZER}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  // ログイン中のユーザー

  public static setUser(account: MeResponse) {
    localStorage.setItem(`${KEY_USER}`, JSON.stringify(account));
    return account;
  }

  public static getUser(): MeResponse | null {
    const saved = localStorage.getItem(`${KEY_USER}`);
    if (saved) {
      const user = JSON.parse(saved) as MeResponse;
      return user;
    } else {
      return null;
    }
  }

  public static removeItem() {
    localStorage.removeItem(`${KEY_USER}`);
  }

  // 個別チャット（テキスト）

  public static setChatText(text: string) {
    localStorage.setItem(`${KEY_CHAT_TEXT}`, text);
    return text;
  }

  public static getChatText(): string | null {
    const saved = localStorage.getItem(`${KEY_CHAT_TEXT}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static removeChatText() {
    localStorage.removeItem(`${KEY_CHAT_TEXT}`);
  }

  // 個別チャット（chaidId）

  public static setChatChildId(childId: string) {
    localStorage.setItem(`${KEY_CHAT_CHAILDID}`, childId);
    return childId;
  }

  public static getChatChildId(): string | null {
    const saved = localStorage.getItem(`${KEY_CHAT_CHAILDID}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static removeChatChildId() {
    localStorage.removeItem(`${KEY_CHAT_CHAILDID}`);
  }

  // 一斉送信（テキスト）

  public static setBulkChatText(text: string) {
    localStorage.setItem(`${KEY_BULK_CHAT_TEXT}`, text);
    return text;
  }

  public static getBulkChatText(): string | null {
    const saved = localStorage.getItem(`${KEY_BULK_CHAT_TEXT}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static removeBulkChatText() {
    localStorage.removeItem(`${KEY_BULK_CHAT_TEXT}`);
  }

  // 一斉送信（宛先）

  public static setBulkChatAddress(data: number[]) {
    localStorage.setItem(`${KEY_BULK_CHAT_ADDRESS}`, JSON.stringify(data));
    return data;
  }

  public static getBulkChatAddress(): number[] {
    const address = localStorage.getItem(`${KEY_BULK_CHAT_ADDRESS}`)
    if (address) {
      const saved = JSON.parse(address);
      return saved;
    }
    return [];
  }

  public static removeBulkChatAddress() {
    localStorage.removeItem(`${KEY_BULK_CHAT_ADDRESS}`);
  }

  // 個別日誌（テキスト）

  public static setDiaryText(text: string) {
    localStorage.setItem(`${KEY_DIARY_TEXT}`, text);
    return text;
  }

  public static getDiaryText(): string | null {
    const saved = localStorage.getItem(`${KEY_DIARY_TEXT}`);
    if (saved) {
      return saved;
    }
    return null;
  }

  public static removeDiaryText() {
    localStorage.removeItem(`${KEY_DIARY_TEXT}`);
  }

  // クラス日誌（テキスト）

  public static setClassDiaryText(data: {}) {
    localStorage.setItem(`${KEY_CLASS_DIARY_TEXT}`, JSON.stringify(data));
    return data;
  }

  public static getClassDiaryText() {
    const address = localStorage.getItem(`${KEY_CLASS_DIARY_TEXT}`)
    if (address) {
      const saved = JSON.parse(address);
      return saved;
    }
    return [];
  }

  public static removeClassDiaryText() {
    localStorage.removeItem(`${KEY_CLASS_DIARY_TEXT}`);
  }

  // 記録ページ
  public static setRecordsTabIndex(index: number) {
    localStorage.setItem(KEY_RECORDS_TAB_INDEX, String(index))
  }

  public static getRecordsTabIndex() {
    const indexString = localStorage.getItem(KEY_RECORDS_TAB_INDEX)
    try {
      if (!indexString) {
        throw undefined
      }
      const index = parseInt(indexString, 10)
      return index
    } catch {
      return 0
    }
  }
}
