









import Vue, { PropType } from 'vue';
import { VueLoading } from 'vue-loading-template';

export default Vue.extend({
  name: 'loading',
  components: {
    VueLoading,
  },
  props: {
    height: {
      type: String,
      default: '100vh',
    },
  },
});
