






































import Vue, { PropType } from 'vue';

import { NurseryClassResponse } from 'chaild-api/lib/index';

import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';

export default Vue.extend({
  components: {},
  data: () => {
    return {};
  },
  props: {
    date: {
      type: Object as PropType<any>,
      required: true,
    },
    isYear: {
      type: Boolean,
      default: true,
    },
    beforeTheDate: {
      type: Number,
      default: 0,
    },
    afterTheDate: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    todayDate() {
      return this.date.format('M月D日');
    },
    todayDayWeek() {
      return this.date.format('ddd');
    },
    todayYear() {
      return this.date.format('YYYY年');
    },
  },
  methods: {
    addDay() {
      this.$emit('addDay');
    },
    subtractDay() {
      this.$emit('subtractDay');
    },
  },
  async mounted() {},
});
