





























































import Vue from 'vue';
import { auth0Module } from '@/store/dataModules/auth0Module';
import { MeNurserySchoolResponse, MeResponse } from 'chaild-api/lib';
import LocalDataService from '@/service/LocalDataService';
export default Vue.extend({
  computed: {
    me(): MeResponse | null {
      const me = LocalDataService.getUser();
      return me;
    },
    currentScool(): MeNurserySchoolResponse | undefined {
      if (this.me) {
        let nurserySchool = this.me.nurserySchools.find(nursery => nursery.isDefault === true);
        if (this.me.nurserySchools.length === 0) {
          return undefined
        }
        if (!nurserySchool) {
          nurserySchool = this.me.nurserySchools[0];
        }
        return nurserySchool;
      }
      return undefined;
    },
    features(): Array<{
      name: string; // 機能名
      slug: string; // 機能スラッグ
      isEnabled: boolean; // 機能有効フラグ
    }> {
      if (this.currentScool) {
        const messageFeature = this.currentScool.applicationPlan.nurseryMobile.featureGroups.find((fg) => fg.name === "設定");
        if (!messageFeature) {
          return [];
        }
        return messageFeature.features.filter((f) => f.isEnabled);
      }
      return [];
    },
    shouldShowCalendar() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-setting-calendar");
      if (find) {
        return true;
      }
      return false;
    },
    shouldShowNursery() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-setting-auth");
      if (find) {
        return true;
      }
      return false;
    },
    shouldShowNotification() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-setting-notification");
      if (find) {
        return true;
      }
      return false;
    },
    shouldShowLogout() {
      const find = this.features.find((f) => f.slug === "nursery-mobile-setting-logout");
      if (find) {
        return true;
      }
      return false;
    },
  },
  methods: {
    logout() {
      // auth0Module.signinProcess();
        auth0Module.logout();
    },
  },
});
