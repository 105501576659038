import { GetCurrentVersion } from './../../model/Response';
import LocalDataService from '@/service/LocalDataService';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiEvent from '@/api/ApiEvent';

import { YearlyEventBulkResponseForNursery, YearlyEventResponseForNursery } from 'chaild-api/lib/index';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
export interface EventState {
  events: YearlyEventResponseForNursery[];
  total: number;
}

@Module({ dynamic: true, store, name: 'event', namespaced: true })
class EventModule extends VuexModule implements EventState {

  public events: YearlyEventResponseForNursery[] = [];
  public total: number = 0;

  public get eventsIncludedDate(): YearlyEventResponseForNursery[] {
    const arr: YearlyEventResponseForNursery[] = [];
    this.events.map((e) => {
      const date = e.date;
      const index = arr.findIndex((a) => a.date === date);
      if (index === -1) {
        arr.push(e);
      } else {
        arr[index].eventName = `${arr[index].eventName}\n${e.eventName}`;
        // arr[index].children.concat(e.children);
      }
    });
    arr.sort((a, b) => {
      // 昇順に並び替え
      const aTime = dayjs(a.date);
      const bTime = dayjs(b.date);
      if (dayjs(aTime).isBefore(bTime)) { return -1; }
      if (dayjs(aTime).isAfter(bTime)) { return 1; }
      return 0;
    });
    return arr;
  }

  @Action({ rawError: true })
  public async fetchEvent(input: { nurseryId: number, from: string, to: string }) {
    try {
      const response: YearlyEventBulkResponseForNursery = await ApiEvent.fetchEvent(input);
      this.setEvent(response.yearlyEvents)
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setEvent(event: YearlyEventResponseForNursery[]) {
    this.events = event;
  }

}

export const eventModule = getModule(EventModule);
