import axios, { AxiosError } from 'axios';

import LocalDataService from '@/service/LocalDataService';
import { when } from '@/service/Switch';
import Swal from 'sweetalert2';
import querystring from 'query-string';
import { auth0Module } from '@/store/dataModules/auth0Module';

const baseUrl = process.env.VUE_APP_API_URL;
const xkey = process.env.VUE_APP_X_API_KEY;
// memoru
const memoruBaseUrl = process.env.VUE_APP_MEMORU_API_URL;
const memoruXkey = process.env.VUE_APP_MEMORU_X_API_KEY;

const client = axios.create();

interface Response {
  status: number;
  config: {
    url: string;
  };
  data: {
    [key: string]: string;
  };
}

interface ErrorResponse {
  status: number;
  config: {
    url: string;
  };
  data: {
    data: {
      message: string;
    };
    status: string;
  };
}

const getHeaders = async () => {
  const token = await auth0Module.getAuth0Token();
  // console.log('token', token)
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json; charset=utf-8',
    'x-api-key': xkey,
  };
};

const getMemoruHeaders = async () => {
  const token = await auth0Module.getAuth0Token();
  // console.log('token', token)
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json; charset=utf-8',
    'x-api-key': memoruXkey,
  };
};

export enum ErrorType {
  UNAUTHORIZED = 'unauthorized',
  FIRSTLOGIN = 'firstLogin',
  NO_TOKEN = 'no_token',
}

client.interceptors.response.use(
  async response => {
    try {
      const r: Response = response as Response;
      // return response
      if (r.config.url) {
        if (r.status !== 200) {
          throw Promise.reject(response);
        }
        if (r.data.error) {
          if (r.data.error === 'E003') {
            throw ErrorType.FIRSTLOGIN;
          } else if (r.data.error === 'E014') {
          } else {
            // Swal.fire(r.data.error, r.data.message, 'warning');
            const errorCode = String(r.data.error);
            const message = getErrorMessage(errorCode);
            Swal.fire(`${message}`, ``, 'warning');
            throw Promise.reject(response);
          }
        }
        return response;
      } else {
        if (r.data.status !== '0') {
          // status != 0の時は、何かしらのエラー
          throw Promise.reject(response);
        }
        return response;
      }
    } catch (error) {
      throw error;
    }
  },
  async error => {
    try {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        if (axiosError.response.status >= 500) {
          // 認証エラーの時は、サインイン画面に飛ばす
          const status = String(axiosError.response.status);
          const message = String(axiosError.response.data.message);
          Swal.fire(status, message, 'error');
          throw Promise.reject(error);
        }
        if (axiosError.response.status === 401) {
          if (process.env) {
            window.location.href = '/logout';
            throw Promise.reject(ErrorType.UNAUTHORIZED);
          }
        }
        if (axiosError.response.status === 400) {
          // 認証エラーの時は、サインイン画面に飛ばす
          const status = String(axiosError.response.status);
          const message = getErrorMessage(axiosError.response.data.error);
          Swal.fire(status, message, 'error');
          throw Promise.reject(error);
        }
      }
    } catch (error) {
      throw Promise.reject(error);
    }
  }
);

const getErrorMessage = (errorCode: string): string => {
  return when(errorCode)
    .on(
      v => v === 'E000',
      () =>
        'システムに予期せぬエラーが発生しました。しばらく時間をおいてアクセス頂くかカスタマーサポートにお問い合わせください。'
    )
    .on(
      v => v === 'E001',
      () => 'API仕様書で指定されているのとパラメータが違います。'
    )
    .on(
      v => v === 'E002',
      () => 'トークンが失効しています。'
    )
    .on(
      v => v === 'E003',
      () => 'ユーザーが存在しません'
    )
    .on(
      v => v === 'E004',
      () => 'ユーザーが既に存在しています。'
    )
    .on(
      v => v === 'E005',
      () => '指定されたObjectTypeが不正です。'
    )
    .on(
      v => v === 'E006',
      () => '朝食の記録が存在しません。'
    )
    .on(
      v => v === 'E007',
      () => '朝食の記録が既に存在します。'
    )
    .on(
      v => v === 'E008',
      () => '夕食の記録が存在しません。'
    )
    .on(
      v => v === 'E009',
      () => '夕食の記録が既に存在します。'
    )
    .on(
      v => v === 'E010',
      () => '排便の記録が存在しません。'
    )
    .on(
      v => v === 'E011',
      () => 'ミルクの記録が存在しません。'
    )
    .on(
      v => v === 'E012',
      () => '睡眠の記録が存在しません。'
    )
    .on(
      v => v === 'E013',
      () => '体温の記録が存在しません。'
    )
    .on(
      v => v === 'E014',
      () => '保育日誌の記録が存在しません。'
    )
    .on(
      v => v === 'E015',
      () => '保育日誌の記録が既に存在します。'
    )
    .on(
      v => v === 'E016',
      () => '喫食の記録が存在しません。'
    )
    .on(
      v => v === 'E017',
      () => '喫食の記録が既に存在します。'
    )
    .on(
      v => v === 'E018',
      () => '欠席期間に重複があるため登録できません。'
    )
    .on(
      v => v === 'E019',
      () => '選択された理由が不正です。'
    )
    .on(
      v => v === 'E020',
      () => '選択された送迎者が不正です。'
    )
    .on(
      v => v === 'E021',
      () => 'メッセージが見つかりません。'
    )
    .on(
      v => v === 'E022',
      () => '送迎のデータが見つかりません。'
    )
    .on(
      v => v === 'E023',
      () => 'クラスが見つかりません。'
    )
    .on(
      v => v === 'E024',
      () => '指定した園児が見つかりません。'
    )
    .on(
      v => v === 'E025',
      () => '指定した園児は既に自分の家族として登録されています。'
    )
    .on(
      v => v === 'E026',
      () => '指定した家族が見つかりません。'
    )
    .on(
      v => v === 'E027',
      () => 'ユーザーが指定された家族に所属していません。'
    )
    .on(
      v => v === 'E028',
      () => '指定されたユーザーは既に指定された家族に招待されています'
    )
    .on(
      v => v === 'E029',
      () => '指定のフォーマットが見つかりません'
    )
    .on(
      v => v === 'E030',
      () => '園児IDが指定されていません'
    )
    .on(
      v => v === 'E031',
      () => '指定されたフィード情報が存在しません。'
    )
    .on(
      v => v === 'E032',
      () => '指定されたフィードの詳細情報が存在しません。'
    )
    .on(
      v => v === 'E033',
      () => '指定されたレポートはユーザーのものではありません。'
    )
    .on(
      v => v === 'E034',
      () => '指定された日付の登園・降園の記録が既に存在します。'
    )
    .on(
      v => v === 'E035',
      () => '登園・降園の記録が存在しません。'
    )
    .on(
      v => v === 'E036',
      () => '指定された日付の登園記録がありません。'
    )
    .on(
      v => v === 'E037',
      () => '指定された日付の登園記録が既に存在します。'
    )
    .on(
      v => v === 'E038',
      () => '指定された日付の降園記録が既に存在します。'
    )
    .on(
      v => v === 'E039',
      () => '指定された登園・降園の記録はこの園児のものではありません。'
    )
    .on(
      v => v === 'E040',
      () => '不正な保育園が指定されました。'
    )
    .on(
      v => v === 'E041',
      () => '平仮名の判定に失敗しました。恐れ入りますがお問い合わせください。'
    )
    .on(
      v => v === 'E042',
      () => '内部的に使用されているIDが不正です。'
    )
    .on(
      v => v === 'E043',
      () => 'uqidが不正です。'
    )
    .on(
      v => v === 'E044',
      () => 'CheckSumが不正です。'
    )
    .on(
      v => v === 'E045',
      () => '指定された献立は存在しません。'
    )
    .on(
      v => v === 'E046',
      () => '管理者ユーザーのみが許可された操作です。'
    )
    .on(
      v => v === 'E047',
      () => '指定したクラスは指定した保育園に所属していません'
    )
    .on(
      v => v === 'E048',
      () => '指定した日時、種類の献立は既に存在しています。'
    )
    .on(
      v => v === 'E049',
      () => '指定した献立は指定した保育園の物ではありません。'
    )
    .on(
      v => v === 'E050',
      () => 'ユーザーは指定した保育園に所属していません。'
    )
    .on(
      v => v === 'E051',
      () => '管理者ユーザーのみが許可された操作です。'
    )
    .on(
      v => v === 'E052',
      () => '日誌の承認ステータスが不正です。'
    )
    .on(
      v => v === 'E053',
      () => '園日誌の記録が存在しません。'
    )
    .on(
      v => v === 'E054',
      () => 'このユーザーには承認権限がありません。'
    )
    .on(
      v => v === 'E055',
      () => 'この園日誌の記録者はこのユーザーではありません。'
    )
    .on(
      v => v === 'E056',
      () => 'この園日誌は申請中ではありません。'
    )
    .on(
      v => v === 'E057',
      () => '同一の権限で、他のユーザーが既に承認しています。'
    )
    .on(
      v => v === 'E058',
      () => '日誌の初回作成時の状態は、申請・下書きのいずれかである必要があります。'
    )
    .on(
      v => v === 'E059',
      () => 'おたよりの記録が存在しません。'
    )
    .on(
      v => v === 'E060',
      () => 'このユーザーは指定した献立の保育園に所属していません。'
    )
    .on(
      v => v === 'E061',
      () => '指定した献立に対する検食記録は既に存在しています。'
    )
    .on(
      v => v === 'E062',
      () => '指定された検食記録は存在しません。'
    )
    .on(
      v => v === 'E063',
      () => '指定した年間行事は存在しません。'
    )
    .on(
      v => v === 'E064',
      () => '指定したクラスIDは指定した保育園の物ではありません。'
    )
    .on(
      v => v === 'E065',
      () => 'socketIdの形式が不正です。恐れ入りますがシステム管理者にお問い合わせください。'
    )
    .on(
      v => v === 'E066',
      () => '午睡チェックパターンが存在しません。'
    )
    .on(
      v => v === 'E067',
      () => '担当スタッフが重複しています。'
    )
    .on(
      v => v === 'E068',
      () => '指定したスタッフは指定した保育園に所属していません。'
    )
    .on(
      v => v === 'E069',
      () => 'この午睡チェックパターンはこの園のものではありません。'
    )
    .on(
      v => v === 'E070',
      () => 'この園児はこの保育園に所属していません。'
    )
    .otherwise(
      () =>
        'システムに予期せぬエラーが発生しました。しばらく時間をおいてアクセス頂くかカスタマーサポートにお問い合わせください。'
    );
};

// REST
export default class ApiClient {
  public static async get(endpoint: string, params: { [key: string]: any } | null) {
    if (params) {
      try {
        const response = await client.get(
          `${baseUrl}${endpoint}?${querystring.stringify(params, { skipNull: true })}`,
          {
            headers: await getHeaders(),
            data: {},
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const response = await client.get(`${baseUrl}${endpoint}`, {
          headers: await getHeaders(),
          data: {},
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }

  public static async post(endpoint: string, params: { [key: string]: any } | null, isAuth = true) {
    const token = await auth0Module.getAuth0Token();
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    let headers: { [key: string]: string };
    if (isAuth) {
      headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': xApiKey ? xApiKey : '',
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = { 'Content-Type': 'application/json' };
    }
    return client
      .post(`${baseUrl}${endpoint}`, JSON.stringify(params), { headers })
      .then(response => response.data);
  }

  public static async put(endpoint: string, params: { [key: string]: any } | null) {
    const token = await auth0Module.getAuth0Token();
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': xApiKey ? xApiKey : '',
      Authorization: `Bearer ${token}`,
    };
    if (params) {
      return client
        .put(`${baseUrl}${endpoint}`, JSON.stringify(params), { headers })
        .then(response => response.data);
    } else {
      return client.put(`${baseUrl}${endpoint}`, { headers }).then(response => response.data);
    }
  }

  public static async delete(endpoint: string, params: { [key: string]: any } | null) {
    const token = await auth0Module.getAuth0Token();
    const xApiKey = process.env.VUE_APP_X_API_KEY;
    const headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': xApiKey ? xApiKey : '',
      Authorization: `Bearer ${token}`,
    };
    if (params) {
      return client
        .delete(`${baseUrl}${endpoint}?${querystring.stringify(params, { skipNull: true })}`, {
          headers,
          data: {},
        })
        .then(response => response.data);
    } else {
      return client
        .delete(`${baseUrl}${endpoint}`, {
          headers,
          data: {},
        })
        .then(response => response.data);
    }
  }

  public static async memoruGet(endpoint: string, params: { [key: string]: any } | null) {
    if (params) {
      try {
        const response = await client.get(
          `${memoruBaseUrl}${endpoint}?${querystring.stringify(params, { skipNull: true })}`,
          {
            headers: await getMemoruHeaders(),
            data: {},
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const response = await client.get(`${memoruBaseUrl}${endpoint}`, {
          headers: await getMemoruHeaders(),
          data: {},
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }

  public static async memoruPost(
    endpoint: string,
    params: { [key: string]: any } | null,
    isAuth = true
  ) {
    const token = await auth0Module.getAuth0Token();
    const xApiKey = memoruXkey;
    let headers: { [key: string]: string };
    if (isAuth) {
      headers = {
        'Content-Type': 'application/json',
        'X-API-KEY': xApiKey ? xApiKey : '',
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = { 'Content-Type': 'application/json' };
    }
    return client
      .post(`${memoruBaseUrl}${endpoint}`, JSON.stringify(params), { headers })
      .then(response => response.data);
  }

  public static async memoruPut(endpoint: string, params: { [key: string]: any } | null) {
    const token = await auth0Module.getAuth0Token();
    const xApiKey = memoruXkey;
    const headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': xApiKey ? xApiKey : '',
      Authorization: `Bearer ${token}`,
    };
    if (params) {
      return client
        .put(`${memoruBaseUrl}${endpoint}`, JSON.stringify(params), { headers })
        .then(response => response.data);
    } else {
      return client.put(`${baseUrl}${endpoint}`, { headers }).then(response => response.data);
    }
  }

  public static async memoruDelete(endpoint: string, params: { [key: string]: any } | null) {
    const token = await auth0Module.getAuth0Token();
    const xApiKey = memoruXkey;
    const headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': xApiKey ? xApiKey : '',
      Authorization: `Bearer ${token}`,
    };
    if (params) {
      return client
        .delete(
          `${memoruBaseUrl}${endpoint}?${querystring.stringify(params, { skipNull: true })}`,
          {
            headers,
            data: {},
          }
        )
        .then(response => response.data);
    } else {
      return client
        .delete(`${memoruBaseUrl}${endpoint}`, {
          headers,
          data: {},
        })
        .then(response => response.data);
    }
  }
}
