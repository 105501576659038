

























































































import Vue, { PropType } from 'vue';
import { MealResponse, NurseryChildResponse, MealStatus, MealStatusType } from 'chaild-api/lib';
import EatingStatusItem from '@/components/molecules/EatingStatusItem.vue';

import { when } from '@/service/Switch';

export default Vue.extend({
  components: {
    EatingStatusItem,
  },
  props: {
    classMealReport: {
      type: Array as PropType<MealResponse[]>,
      required: true,
      default: [],
    },
    child: {
      type: Object as PropType<NurseryChildResponse>,
      required: true,
    },
    fullBtnLoading: {
      type: Boolean,
      default: false,
    },
    btnLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      inputComment: '',
    };
  },
  computed: {
    meal(): MealResponse | null {
      if (this.classMealReport.length > 0) {
        const find = this.classMealReport.find(meal => meal.childId === this.child.childId);
        if (find) {
          this.inputComment = find.comment;
          return find;
        }
      }
      return null;
    },
  },
  methods: {
    sendMessage() {
      if (this.meal) {
        const input = {
          childId: this.child.childId,
          mealId: this.meal.mealId,
          stapleFood: this.meal.stapleFood,
          mainDish: this.meal.mainDish,
          sideDish: this.meal.sideDish,
          soup: this.meal.soup,
          dessert: this.meal.dessert,
          milk: this.meal.milk,
          snack: this.meal.snack,
          comment: this.inputComment,
        };
        this.$emit('sendMessage', input);
      }
    },
    changeStatusItem(status: MealStatusType, text: string) {
      let input = {};
      if (this.meal) {
        if (text === '主食') {
          input = {
            childId: this.child.childId,
            mealId: this.meal.mealId,
            stapleFood: status,
            mainDish: this.meal.mainDish,
            sideDish: this.meal.sideDish,
            soup: this.meal.soup,
            dessert: this.meal.dessert,
            milk: this.meal.milk,
            snack: this.meal.snack,
          };
        } else if (text === '主菜') {
          input = {
            childId: this.child.childId,
            mealId: this.meal.mealId,
            stapleFood: this.meal.stapleFood,
            mainDish: status,
            sideDish: this.meal.sideDish,
            soup: this.meal.soup,
            dessert: this.meal.dessert,
            milk: this.meal.milk,
            snack: this.meal.snack,
          };
        } else if (text === '副菜') {
          input = {
            childId: this.child.childId,
            mealId: this.meal.mealId,
            stapleFood: this.meal.stapleFood,
            mainDish: this.meal.mainDish,
            sideDish: status,
            soup: this.meal.soup,
            dessert: this.meal.dessert,
            milk: this.meal.milk,
            snack: this.meal.snack,
          };
        } else if (text === '汁もの') {
          input = {
            childId: this.child.childId,
            mealId: this.meal.mealId,
            stapleFood: this.meal.stapleFood,
            mainDish: this.meal.mainDish,
            sideDish: this.meal.sideDish,
            soup: status,
            dessert: this.meal.dessert,
            milk: this.meal.milk,
            snack: this.meal.snack,
          };
        } else if (text === 'デザート') {
          input = {
            childId: this.child.childId,
            mealId: this.meal.mealId,
            stapleFood: this.meal.stapleFood,
            mainDish: this.meal.mainDish,
            sideDish: this.meal.sideDish,
            soup: this.meal.soup,
            dessert: status,
            milk: this.meal.milk,
            snack: this.meal.snack,
          };
        } else if (text === '牛乳') {
          input = {
            childId: this.child.childId,
            mealId: this.meal.mealId,
            stapleFood: this.meal.stapleFood,
            mainDish: this.meal.mainDish,
            sideDish: this.meal.sideDish,
            soup: this.meal.soup,
            dessert: this.meal.dessert,
            milk: status,
            snack: this.meal.snack,
          };
        } else if (text === 'おやつ') {
          input = {
            childId: this.child.childId,
            mealId: this.meal.mealId,
            stapleFood: this.meal.stapleFood,
            mainDish: this.meal.mainDish,
            sideDish: this.meal.sideDish,
            soup: this.meal.soup,
            dessert: this.meal.dessert,
            milk: this.meal.milk,
            snack: status,
          };
        }
      }
      this.$emit('changeStatusItem', input);
    },
    createMealReport(childId: number) {
      this.$emit('createMealReport', childId);
    },
    fullEat() {
      this.$emit('fullEat', this.meal);
    },
    showMealStatusText(status: MealStatusType): string {
      const temp = when(status)
        .on(
          v => v === MealStatus.refill,
          () => 'おかわり'
        )
        .on(
          v => v === MealStatus.finished,
          () => '完食'
        )
        .on(
          v => v === MealStatus.unfinished,
          () => '少し残した'
        )
        .on(
          v => v === MealStatus.half,
          () => '半分食べた'
        )
        .on(
          v => v === MealStatus.little,
          () => '少し食べた'
        )
        .on(
          v => v === MealStatus.notEat,
          () => '食べなかった'
        )
        .on(
          v => v === MealStatus.notServed,
          () => '未提供'
        )
        .otherwise(() => '');
      return temp;
    },
    showMealStatus(status: MealStatusType): string {
      const temp = when(status)
        .on(
          v => v === MealStatus.refill,
          () => 'refill'
        )
        .on(
          v => v === MealStatus.finished,
          () => 'finished'
        )
        .on(
          v => v === MealStatus.unfinished,
          () => 'unfinished'
        )
        .on(
          v => v === MealStatus.half,
          () => 'half'
        )
        .on(
          v => v === MealStatus.little,
          () => 'little'
        )
        .on(
          v => v === MealStatus.notEat,
          () => 'notEat'
        )
        .on(
          v => v === MealStatus.notServed,
          () => 'notServed'
        )
        .otherwise(() => '');
      return temp;
    },
  },
  mounted: async function() {},
});
