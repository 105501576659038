





import Vue from 'vue';
import CommunicationChatContainer from '@/container/communication/CommunicationChatContainer.vue';
import { pusherModule } from '@/store/dataModules/pusherModule';

export default Vue.extend({
  components: {
    CommunicationChatContainer,
  },
  mounted() {
  },
});
