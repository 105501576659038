



















































































import Vue from 'vue';
import MessageHandle from '@/components/MessageHandle.vue';
import LocalDataService from '@/service/LocalDataService';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import { GroupChatResponse, NurseryClassResponse } from 'chaild-api/lib';
import { chatModule } from '@/store/dataModules/chatModule';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
dayjs.locale(ja);

interface Address {
  id: string;
  name: string;
}

export default Vue.extend({
  components: {
    MessageHandle,
  },

  data: () => {
    return {
      date: '' as any,
      inputAdresses: [] as number[],
      inputText: '',
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
      btnSendLoading: false,
      btnDraftLoading: false,
      btnReadyLoading: false,
      draftBulkChatId: '',
      readyBulkChatId: '',
    };
  },

  computed: {
    allClass(): NurseryClassResponse[] | null {
      if (nurseryModule.allClasses) {
        return nurseryModule.allClasses;
      }
      return null;
    },
    bulkChatsDraft(): GroupChatResponse[] {
      if (chatModule.bulkChatsDraft) {
        return chatModule.bulkChatsDraft;
      }
      return [];
    },
    bulkChatsReady(): GroupChatResponse[] {
      if (chatModule.bulkChatsReady) {
        return chatModule.bulkChatsReady;
      }
      return [];
    },
    isChatCreateUserMe(): Boolean {
      const queryUserId = this.$route.query.userId as string;
      const user = LocalDataService.getUser();
      if (queryUserId && user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const myUserId = nurserySchool.userId.toString();
        if (myUserId === queryUserId) {
          return true;
        }
        return false;
      }
      return true;
    },
  },

  methods: {
    routerBack() {
      const routes = window.history.length;
      if (routes > 1) {
        this.$router.back();
      } else {
        this.$router.push(`/`);
      }
    },
    remove(item: NurseryClassResponse) {
      const index = this.inputAdresses.findIndex(addressId => addressId === item.classId);
      if (index >= 0) {
        this.inputAdresses.splice(index, 1);
      }
    },
    saveDraft: async function() {
      if (this.inputText) {
        try {
          this.btnDraftLoading = true;
          if (this.draftBulkChatId) {
            await chatModule.saveAllChatDraft({
              groupChatId: Number(this.draftBulkChatId),
              body: this.inputText,
            });
            this.$router.push(`/communication/new-message`);
          } else {
            const classIds = this.inputAdresses.filter((address) => {
              const findFromClassOptions = this.allClass?.find((c) => c.classId === address)
              if (findFromClassOptions) {
                return true
              }
              return false
            })
            await chatModule.saveAllChatDraft({
              // childIds: this.childId,
              classIds,
              date: this.date.format('YYYY-MM-DD'),
              body: this.inputText,
            });
          }
          this.snackBarText = '下書きに保存しました';
          this.snackbar = true;
          this.inputText = '';
        } catch {
        } finally {
          this.btnDraftLoading = false;
        }
      }
    },
    saveSendReady: async function() {
      if (this.inputText) {
        try {
          this.btnReadyLoading = true;
          if (this.readyBulkChatId) {
            console.log({
              classIds: this.inputAdresses,
              date: this.date.format('YYYY-MM-DD'),
              body: this.inputText,
            });
            await chatModule.updateAllChatReady({
              groupChatId: this.readyBulkChatId,
              body: this.inputText,
              classIds: this.inputAdresses,
            });
            this.$router.push(`/communication/new-message`);
          } else {
            const classIds = this.inputAdresses.filter((address) => {
              const findFromClassOptions = this.allClass?.find((c) => c.classId === address)
              if (findFromClassOptions) {
                return true
              }
              return false
            })
            await chatModule.saveAllChatReady({
              // childIds: this.childId,
              classIds,
              date: this.date.format('YYYY-MM-DD'),
              body: this.inputText,
            });
          }
          this.snackBarText = '送信準備BOXに保存しました';
          this.snackbar = true;
          this.inputText = '';
        } catch {
        } finally {
          this.btnReadyLoading = false;
        }
      }
    },
    sendMessage: async function() {
      if (this.inputText) {
        try {
          this.btnSendLoading = true;
          const classIds = this.inputAdresses.filter((address) => {
              const findFromClassOptions = this.allClass?.find((c) => c.classId === address)
              if (findFromClassOptions) {
                return true
              }
              return false
            })
          if (this.draftBulkChatId) {
            await chatModule.saveAllChatDraft({
              groupChatId: Number(this.draftBulkChatId),
              body: this.inputText,
              classIds,
            });
            const ids = [Number(this.draftBulkChatId)];
            await chatModule.sendAllChat({
              groupChatIds: ids,
            });
          } else if (this.readyBulkChatId) {
            await chatModule.updateAllChatReady({
              groupChatId: this.readyBulkChatId,
              body: this.inputText,
              classIds,
            });
            const ids = [Number(this.readyBulkChatId)];
            await chatModule.sendAllChat({
              groupChatIds: ids,
            });
          } else {
            await chatModule.sendAllChat({
              // childIds: this.childId,
              classIds,
              date: this.date.format('YYYY-MM-DD'),
              body: this.inputText,
            });
          }
          this.snackBarText = '送信しました';
          this.snackbar = true;
          this.inputText = '';
          this.inputAdresses = []
        } catch {
        } finally {
          this.btnSendLoading = false;
        }
      }
    },
  },

  async created() {
    this.date = dayjs();
  },

  async mounted() {
    const localText = LocalDataService.getBulkChatText();
    if (localText) {
      this.inputText = localText;
    }
    const localAddress = LocalDataService.getBulkChatAddress();
    if (localAddress) {
      this.inputAdresses = localAddress;
    }
    const user = LocalDataService.getUser();
    if (user) {
      let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
      if (!nurserySchool) {
        nurserySchool = user.nurserySchools[0];
      }
      const nurseryId = nurserySchool.nurseryId;
      await nurseryModule.getNurseryClass(nurseryId);

      // 下書きからの遷移の場合(一斉)
      this.draftBulkChatId = this.$route.query.draftBulkChatId as string;
      if (this.draftBulkChatId) {
        await chatModule.getAllChatDraft(nurseryId);
        if (this.draftBulkChatId && this.bulkChatsDraft.length > 0) {
          const find = this.bulkChatsDraft.find(
            chat => chat.groupChatId === Number(this.draftBulkChatId)
          );
          if (find) {
            this.inputAdresses = [];
            find.to.classes.map(clas => {
              this.inputAdresses.push(clas.classId);
            });
            find.to.children.map(child => {
              this.inputAdresses.push(child.childId);
            });
            this.inputText = find.body;
          }
        }
      }

      // 送信準備からの遷移の場合(一斉)
      this.readyBulkChatId = this.$route.query.readyBulkChatId as string;
      if (this.readyBulkChatId) {
        await chatModule.getAllChatReady(nurseryId);
        if (this.readyBulkChatId && this.bulkChatsReady.length > 0) {
          const find = this.bulkChatsReady.find(
            chat => chat.groupChatId === Number(this.readyBulkChatId)
          );
          if (find) {
            this.inputAdresses = [];
            find.to.classes.map(clas => {
              this.inputAdresses.push(clas.classId);
            });
            find.to.children.map(child => {
              this.inputAdresses.push(child.childId);
            });
            this.inputText = find.body;
          }
        }
      }
    }
  },

  beforeDestroy() {
    if (this.inputText) {
      LocalDataService.setBulkChatText(this.inputText);
    } else {
      LocalDataService.removeBulkChatText();
    }
    if (this.inputAdresses.length > 0) {
      const classIds = this.inputAdresses.filter((address) => {
        const findFromClassOptions = this.allClass?.find((c) => c.classId === address)
        if (findFromClassOptions) {
          return true
        }
        return false
      })
      LocalDataService.setBulkChatAddress(classIds);
    } else {
      LocalDataService.removeBulkChatAddress();
    }
  },
});
