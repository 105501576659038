import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/views/Home.vue';
import ClassRecord from '@/views/ClassRecord.vue';
import ChildRecord from '@/views/ChildRecord.vue';
import Chat from '@/views/Chat.vue';
import Draft from '@/views/Draft.vue';
import ReadySendBox from '@/views/ReadySendBox.vue';
import NewMessage from '@/views/NewMessage.vue';
import SignIn from '../views/SignIn.vue';
import SignUp from '../views/SignUp.vue';
import ChangeNursery from '../views/ChangeNursery.vue';
import EventCalender from '../views/EventCalender.vue';
import ChangeNotification from '../views/ChangeNotification.vue';
import ClassDiary from '../views/ClassDiary.vue';
import ParentsRecord from '../views/ParentsRecord.vue';
import Maintenance from '../views/Maintenance.vue';
import MemoruAlbum from '../views/memoru/MemoruAlbum.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/communication/chat/:childId',
    name: 'Chat',
    component: Chat,
  },
  {
    path: '/communication/draft',
    name: 'Draft',
    component: Draft,
  },
  {
    path: '/communication/ready-send-box',
    name: 'ReadySendBox',
    component: ReadySendBox,
  },
  {
    path: '/communication/new-message',
    name: 'NewMessage',
    component: NewMessage,
  },
  {
    path: '/record/class/:classId',
    name: 'ClassRecord',
    component: ClassRecord,
  },
  {
    path: '/record/class/:classId/child/:childId',
    name: 'ChildRecord',
    component: ChildRecord,
  },
  {
    path: '/record/class-diary/:classId',
    name: 'ClassDiary',
    component: ClassDiary,
  },
  {
    path: '/record/parents-record/class/:classId/child/:childId',
    name: 'ParentsRecord',
    component: ParentsRecord,
  },
  {
    path: '/album/:albumId',
    name: 'MemoruAlbum',
    component: MemoruAlbum,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/change-nursery',
    name: 'ChangeNursery',
    component: ChangeNursery,
  },
  {
    path: '/event-calender',
    name: 'EventCalender',
    component: EventCalender,
  },
  // {
  //   path: '/memoru',
  //   name: 'MemoruTop',
  //   component: MemoruTop,
  // },
  {
    path: '/change-notification',
    name: 'ChangeNotification',
    component: ChangeNotification,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  const maintenanceMode = process.env.VUE_APP_MANTENANCE_MODE;
  const toQuery = to.query;
  const fromQuery = from.query;
  const toPath = to.path;

  const isMaintenanceMode = maintenanceMode && maintenanceMode === 'true';
  const toMagicWord = toQuery.vadar && toQuery.vadar === 'anakin';
  const fromMagicWord = fromQuery.vadar && fromQuery.vadar === 'anakin';

  if (isMaintenanceMode) {
    if (toMagicWord) {
      if (toPath === '/maintenance') {
        next('/home');
        return;
      }
      next();
      return;
    }

    if (fromMagicWord) {
      // ここで to のクエリーに from のクエリーを渡す
      const query = Object.assign(to.query, { vadar: 'anakin' });
      next({ name: to.name || '', params: to.params, query });
      return;
    }

    // should go to maintenance
    if (toPath === '/maintenance') {
      next();
      return;
    }
    next('/maintenance');
    return;
  }

  // maintenanceMode = false
  if (toPath === '/maintenance') {
    next('/home');
    return;
  }

  if (to.path === '/signin') {
    next();
  } else {
    next();
    return;
  }
});

export default router;
