import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#176ED3',
        secondary: '#ee6090',
        defecation: '#02C07C',
        temperature: '#EB2121',
        milk: '#DB069F',
        sleep: '#FF5722',
      },
    },
  },
});
