












































































import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

// model
// import { VForm } from '@/model/vuetify/VForm';
import { MilkResponse, NurseryMilkResponse } from 'chaild-api/lib';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';
import { MilkChoicesResponse } from '@/model/Response';

export default Vue.extend({
  name: 'MilkModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
    milkTemp: {
      type: Object as PropType<NurseryMilkResponse>,
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form() {
      return (this.$refs as any).post_milk_form;
    },
    milkChoice() {
      return diaryReportModule.milkChoice;
    },
    options() {
      const options = [
        { value: 'breast', text: '母乳' },
        { value: 'formula', text: 'ミルク' },
      ];
      return options;
    },
  },

  watch: {
    isOpen: {
      handler(val: boolean) {
        if (val) {
          this.amount = null;
        }
      },
      deep: true,
    },
  },

  data: () => ({
    recordedAt: '',
    amount: null,
    milkCategory: 'breast',
    btnLoading: false,
    isRecordetAtPicker: false,
  }),

  methods: {
    showTextMilkType(type: string) {
      const find = this.options.find(option => option.value === type);
      if (find) {
        return find.text;
      }
      return null;
    },
    allowedStep: (m: any) => m % 5 === 0,
    changeRecordedAt() {
      this.$emit('changeRecordedAt', this.recordedAt);
    },
    changeAmount() {
      this.$emit('changeAmount', this.amount);
    },
    changeMilkCategory() {
      this.$emit('changeMilkCategory', this.milkCategory);
    },
    formatTime(time: string) {
      const nowTime = dayjs(time).format('HH:mm');
      return nowTime;
    },
  },
});
