import LocalDataService from '@/service/LocalDataService';
import { SaveChatReadyRequest, SaveChatDraftRequest, SendGroupChatRequest, SendGroupChatReadyRequest, UpdateGroupChatReadyRequest, PostGroupChatRequest, PostChatsAbsenceRequest, DeleteChatsAbsenceRequest } from './../../model/Request';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiChat from '@/api/ApiChat';

import {
  NurseryAttendanceResponse,
  ChatCommentResponse,
  ChatListResponse,
  ChatResponse, ChatSummaryResponse, GroupChatResponse,
} from 'chaild-api/lib/index';
import {
  SendChatRequest
} from '@/model/Request';


export interface ChatState {
  chats: ChatListResponse | null;
  chatContent: ChatResponse | null;
  chatSummary: ChatSummaryResponse | null;
  chatsAbsence: NurseryAttendanceResponse[]
  chatsReady: ChatResponse[];
  chatsDraft: ChatResponse[];
  bulkChatsReady: GroupChatResponse[];
  bulkChatsDraft: GroupChatResponse[];
}
@Module({ dynamic: true, store, name: 'chat', namespaced: true })
class ChatModule extends VuexModule implements ChatState {

  public chats: ChatListResponse | null = null;
  public chatContent: ChatResponse | null = null;
  public chatsAbsence: NurseryAttendanceResponse[] = [];
  public chatsReady: ChatResponse[] = [];
  public chatsDraft: ChatResponse[] = [];
  public bulkChatsReady: GroupChatResponse[] = [];
  public bulkChatsDraft: GroupChatResponse[] = [];

  public chatSummary: ChatSummaryResponse | null = null;

  // ＝＝＝＝＝＝＝＝
  // 個別チャット
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async fetchChat(input: { childId: number, date: string }) {
    try {
      const response = await ApiChat.fetchChat(input);
      this.setChat(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChat(chats: ChatListResponse) {
    this.chats = chats;
  }

  @Mutation
  public resetChat() {
    this.chats = null;
  }

  public async fetchChatContent(input: { chatId: number, date: string }) {
    try {
      const response = await ApiChat.fetchChatContent(input);
      this.setChatContent(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatContent(chatContent: ChatResponse) {
    this.chatContent = chatContent;
  }

  @Action({ rawError: true })
  public async sendChat(input: SendChatRequest) {
    try {
      const response = await ApiChat.sendChat(input);
      // return response as ChatResponse;
      this.addChat(response)
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public addChat(chat: ChatResponse) {
    //this.chats?.chats.push(chats);
    // Ensure that this.chats and this.chats.chats are defined
    if (!this.chats || !this.chats.chats) {
        console.error("this.chats or this.chats.chats is undefined or null");
        return;
    }

    // Find the index of the chat with the same chatId
    const index = this.chats.chats.findIndex(c => c.chatId === chat.chatId);

    if (index !== -1) {
        // If the chat already exists, replace it
        this.chats.chats.splice(index, 1, chat);
    } else {
        // If the chat does not exist, add it
        this.chats.chats.push(chat);
    }
  }

  @Action({ rawError: true })
  public async confirmChat(input: { chatId: number, childId: number }) {
    try {
      const response = await ApiChat.confirmChat(input);
      return response as ChatResponse;
    } catch (error) {
      throw error;
    }
  }


  @Action({ rawError: true })
  public async saveComment(input: { childId: number, date?: string, comment: string }) {
    try {
      const response = await ApiChat.saveComment(input);
      return response as ChatCommentResponse;
    } catch (error) {
      throw error;
    }
  }


  @Action({ rawError: true })
  public async deleteChat(input: { childId: number, chatId: number }) {
    try {
      const response = await ApiChat.deleteChat(input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // 送信準備
  // ＝＝＝＝＝＝＝＝
  @Action({ rawError: true })
  public async fetchChatReady(nurseryId: number) {
    try {
      const response = await ApiChat.fetchChatReady(nurseryId);
      this.setChatReady(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatReady(chatsReady: ChatResponse[]) {
    this.chatsReady = chatsReady;
  }

  @Action({ rawError: true })
  public async sendChatReady(chatIds: string[]) {
    try {
      const response = await ApiChat.sendChatReady(chatIds);
      return response as ChatResponse;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChatReady(chatIds: string) {
    try {
      const response = await ApiChat.deleteChatReady(chatIds);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async saveChatReady(input: SaveChatReadyRequest) {
    try {
      const response = await ApiChat.saveChatReady(input);
      return response as ChatResponse;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public removeSendedChatReady(sendedChatReady: string[]) {
    if (this.chatsReady.length === 1) {
      this.chatsReady = []
    } else {
      sendedChatReady.map((id) => {
        const readyChatId = parseInt(id)
        const findIndex = this.chatsReady.findIndex(chat => chat.chatId === readyChatId)
        if (findIndex) {
          this.chatsReady.splice(findIndex, 1)
        }
      })
    }
  }

  @Mutation
  public removeSendedBulkChatReady(sendedChatReady: string[]) {
    if (this.bulkChatsReady.length === 1) {
      this.bulkChatsReady = []
    } else {
      sendedChatReady.map((id) => {
        const groupChatId = parseInt(id)
        const findIndex = this.bulkChatsReady.findIndex(chat => chat.groupChatId === groupChatId)
        if (findIndex) {
          this.bulkChatsReady.splice(findIndex, 1)
        }
      })
    }
  }

  // ＝＝＝＝＝＝＝＝
  // 下書き
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async fetchChatDraft(nurseryId: number) {
    try {
      const response = await ApiChat.fetchChatDraft(nurseryId);
      this.setChatDraft(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatDraft(chatsDraft: ChatResponse[]) {
    this.chatsDraft = chatsDraft;
  }

  @Action({ rawError: true })
  public async saveChatDraft(input: SaveChatDraftRequest) {
    try {
      const response = await ApiChat.saveChatDraft(input);
      return response as ChatResponse;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChatDraft(chatIds: string) {
    try {
      const response = await ApiChat.deleteChatDraft(chatIds);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // 一斉送信
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async sendAllChat(input: SendGroupChatRequest) {
    try {
      const response = await ApiChat.sendAllChat(input);
      return response as GroupChatResponse;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async getAllChatDraft(nurseryId: number) {
    try {
      const response = await ApiChat.getAllChatDraft(nurseryId);
      this.setChatAllDraft(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatAllDraft(bulkChatsDraft: GroupChatResponse[]) {
    this.bulkChatsDraft = bulkChatsDraft;

  }

  @Action({ rawError: true })
  public async saveAllChatDraft(input: PostGroupChatRequest) {
    try {
      const response = await ApiChat.saveAllChatDraft(input);
      return response as GroupChatResponse;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async getAllChatReady(nurseryId: number) {
    try {
      const response = await ApiChat.getAllChatReady(nurseryId);
      this.setChatAllReady(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatAllReady(bulkChatsReady: GroupChatResponse[]) {
    this.bulkChatsReady = bulkChatsReady;
  }

  @Action({ rawError: true })
  public async saveAllChatReady(input: SendGroupChatReadyRequest) {
    try {
      const response = await ApiChat.saveAllChatReady(input);
      return response as GroupChatResponse;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteAllChatReady(groupChatIds: string) {
    try {
      const response = await ApiChat.deleteAllChatReady(groupChatIds);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateAllChatReady(input: UpdateGroupChatReadyRequest) {
    try {
      const response = await ApiChat.updateAllChatReady(input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteAllChatDraft(groupChatIds: string) {
    try {
      const response = await ApiChat.deleteAllChatDraft(groupChatIds);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  //  サマリ
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async getChatSummary(input: { nurseryId: number, date: string }) {
    try {
      const localSummary = LocalDataService.getChatSummary()
      if (localSummary && localSummary.date === input.date) {
        this.setChatSummary(localSummary)
        const response = await ApiChat.getChatSummary(input)
        if (localSummary !== response) {
          LocalDataService.setChatSummary(response)
          this.setChatSummary(response)
        }
      } else {
        const response = await ApiChat.getChatSummary(input)
        LocalDataService.setChatSummary(response)
        this.setChatSummary(response)
      }
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatSummary(chatSummary: ChatSummaryResponse) {
    this.chatSummary = chatSummary;
  }

  // ＝＝＝＝＝＝＝＝
  //  欠席連絡
  // ＝＝＝＝＝＝＝＝
  @Action({ rawError: true })
  public async getChatsAbsence(childId: number) {
    try {
      const response = await ApiChat.getChatsAbsence(childId);
      this.setChatsAbsence(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChatsAbsence(chatsAbsence: NurseryAttendanceResponse[]) {
    this.chatsAbsence = chatsAbsence;
  }

  @Action({ rawError: true })
  public async postChatsAbsence(input: PostChatsAbsenceRequest) {
    try {
      const response = await ApiChat.postChatsAbsence(input);
      return response as NurseryAttendanceResponse;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChatsAbsence(input: DeleteChatsAbsenceRequest) {
    try {
      const response = await ApiChat.deleteChatsAbsence(input);
      return response;
    } catch (error) {
      throw error;
    }
  }

}
export const chatModule = getModule(ChatModule);
