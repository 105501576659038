










































































import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';
import { ChildInfoResponse, ChildResponse, NurseryTemperatureResponse } from 'chaild-api/lib';
import { childrenModule } from '@/store/dataModules/childrenModule';

// model
// import { VForm } from '@/model/vuetify/VForm';

export default Vue.extend({
  name: 'MilkModal',

  props: {
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
    temperatureTemp: {
      type: Object as PropType<NurseryTemperatureResponse>,
    },
    inputChild: {
      type: Object as PropType<ChildResponse>
    },
    recordedAt: {
      type: String,
      default: '',
    },
    temperature: {
      type: Number,
      default: NaN
    }
  },

  computed: {
    rules() {
      return rules;
    },
    form() {
      return (this.$refs as any).post_defecation_form;
    },
    options() {
      const options: Array<{ text: string; value: number }> = [];
      for (let i = 350; i < 400; i++) {
        options.push({
          text: `${(i / 10).toFixed(1)}℃`,
          value: Number((i / 10).toFixed(1)),
        });
      }
      return options;
    },
    avarageTemperature() {
      let avarageTemperature = `-` as any
      const healthInfo = childrenModule.childInfo?.healthInfo
      if (healthInfo) {
        healthInfo.map(info => {
          if(info.key === "averageBodyTemperature") {
            avarageTemperature =  info.value as number
          }
        })
      }
      return avarageTemperature
    }
  },

  data: () => ({
    // recordedAt: '',
    // temperature: NaN, 
    btnLoading: false,
    isRecordetAtPicker: false,
  }),

  methods: {
    allowedStep: (m: any) => m % 5 === 0,
    changeRecordedAt() {
      this.$emit('changeRecordedAt', this.recordedAt);
    },
    changeTemperature() {
      this.$emit('changeTemperature', this.temperature);
    },
    formatTime(time: string) {
      const nowTime = dayjs(time).format('HH:mm');
      return nowTime;
    },
  },
  mounted() {
    // this.temperature = 365 / 10
    this.$emit('changeTemperature', this.temperature);
    childrenModule.getChildInfo(this.inputChild.childId)
  }
});
