import { GetCurrentVersion } from './../../model/Response';
import LocalDataService from '@/service/LocalDataService';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiMe from '@/api/ApiMe';

import { MeResponse } from 'chaild-api/lib/index';
import { PostMeRequest, PutMeRequest } from '@/model/Request';

export interface MeState {
  currentVersion: GetCurrentVersion | null;
  tabIndex: number | string;
}

@Module({ dynamic: true, store, name: 'me', namespaced: true })
class MeModule extends VuexModule implements MeState {
  public currentVersion: GetCurrentVersion | null = null;
  public tabIndex: number | string = 0;

  @Action({ rawError: true })
  public async fetchMe() {
    try {
      console.log('fetch me start');
      const response: MeResponse = await ApiMe.fetchMe();
      LocalDataService.setUser(response);
      console.log('fetch me end');
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async postMe() {
    try {
      const response: MeResponse = await ApiMe.postMe();
      LocalDataService.setUser(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async putMe(input: PutMeRequest) {
    try {
      const response: MeResponse = await ApiMe.putMe(input);
      LocalDataService.setUser(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async fetchVersion() {
    try {
      const response: GetCurrentVersion = await ApiMe.fetchVersion();
      this.setVersion(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setVersion(currentVersion: GetCurrentVersion) {
    this.currentVersion = currentVersion;
  }

  @Mutation
  public setTabIndex(index: number | string) {
    this.tabIndex = index;
  }
}

export const meModule = getModule(MeModule);
