import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import VueClipboard from 'vue-clipboard2'
import { longClickDirective } from 'vue-long-click'

Vue.use(VueClipboard)

Vue.config.productionTip = false;
const longClickInstance = longClickDirective({ delay: 400, interval: 50 })
Vue.directive('longclick', longClickInstance)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
