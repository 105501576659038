

















































import { diaryReportModule } from '@/store/dataModules/diaryReportModule';
import { when } from '@/service/Switch';
import {
  DefecationStatusType,
  NurseryChildResponse,
  NurseryClassResponse,
  NurseryDailyReportResponse,
  NurseryDefecationResponse,
  NurseryMilkResponse,
  NurseryTemperatureResponse,
  SleepResponse,
} from 'chaild-api/lib';
import dayjs from 'dayjs';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    classOtherReport: {
      type: Array as PropType<NurseryDailyReportResponse[]>,
      required: true,
      default: [],
    },
    child: {
      type: Object as PropType<NurseryChildResponse>,
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    defecations(): NurseryDefecationResponse[] {
      if (this.classOtherReport.length > 0) {
        const find = this.classOtherReport.find(
          defecation => defecation.childId === this.child.childId
        );
        if (find) {
          return find.defecations;
        }
      }
      return [];
    },
    temperatures(): NurseryTemperatureResponse[] {
      if (this.classOtherReport.length > 0) {
        const find = this.classOtherReport.find(
          temperature => temperature.childId === this.child.childId
        );
        if (find) {
          return find.temperatures;
        }
      }
      return [];
    },
    milks(): NurseryMilkResponse[] {
      if (this.classOtherReport.length > 0) {
        const find = this.classOtherReport.find(milk => milk.childId === this.child.childId);
        if (find) {
          return find.milks;
        }
      }
      return [];
    },
    sleeps(): SleepResponse[] {
      if (this.classOtherReport.length > 0) {
        const find = this.classOtherReport.find(sleep => sleep.childId === this.child.childId);
        if (find) {
          return find.sleeps;
        }
      }
      return [];
    },
  },
  methods: {
    changeDefecation(defecation: NurseryDefecationResponse) {
      this.$emit('changeDefecation', defecation, this.child);
    },
    changeTemperature(temperature: NurseryTemperatureResponse) {
      this.$emit('changeTemperature', temperature, this.child);
    },
    changeMilk(milk: NurseryMilkResponse) {
      this.$emit('changeMilk', milk, this.child);
    },
    changeSleep(sleep: SleepResponse) {
      this.$emit('changeSleep', sleep, this.child);
    },
    showDefecationStatus(state: DefecationStatusType) {
      if (state === 'hard') {
        return '硬い';
      } else if (state === 'normal') {
        return '普通';
      } else if (state === 'soft') {
        return '柔らかい';
      }
    },
    formatTime(time: string) {
      const nowTime = dayjs(time).format('HH:mm');
      return nowTime;
    },
    categoryToText(milkCategory: string): string {
      return  when(milkCategory)
        .on((v) => v === 'formula', () => 'ミルク')
        .on((v) => v === 'breast', () => '母乳')
        .otherwise(() => '');
    },
  },
  mounted: async function() {},
});
