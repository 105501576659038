







import Vue from 'vue';
import ParentsRecordContainer from '@/container/records/ParentsRecordContainer.vue';

export default Vue.extend({
  components: {
    ParentsRecordContainer,
  },
});
