




























































import Vue from 'vue';

import { PostMeRequest } from '@/model/Request';
import { meModule } from '@/store/dataModules/meModule';
import { auth0Module } from '@/store/dataModules/auth0Module';

interface DataType {
  postMeRequest: PostMeRequest;
}

export default Vue.extend({
  data: () => ({
    postMeRequest: {
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
    },
    btnLoading: false,
  }),

  methods: {
    // async postMe() {
    //   try {
    //     this.btnLoading = true
    //     const response = await meModule.postMe(this.postMeRequest);
    //     console.log(response);
    //     this.btnLoading = false
    //     this.$router.push('/');
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     this.btnLoading = false
    //   }
    // },
    toTop() {
      this.$router.push('/');
    },
    logout() {
      auth0Module.logout();
    },
  },
});
