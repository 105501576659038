import { GetCurrentVersion } from './../model/Response';
import ApiClient from '@/api/ApiClient';
import {
  MeResponse,
  MultimediaAlbumListResponse,
  MultimediaAlbumResponse,
  MultimediaListResponse,
  MultimediaResourceResponse,
} from 'chaild-api/lib/index';
import { PostMeRequest, PutMeRequest } from '@/model/Request';
import LocalDataService from '@/service/LocalDataService';
import axios from 'axios';

export default class ApiMemoru {
  public static async listAlbums({
    nurseryId,
    limit = 999,
    skip = 0,
  }: {
    nurseryId: number;
    limit?: number;
    skip?: number;
  }): Promise<MultimediaAlbumListResponse> {
    try {
      const response = await ApiClient.memoruGet(`/album`, { nurseryId, skip, limit });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async getAlbum(albumId: number | string): Promise<MultimediaAlbumResponse> {
    try {
      const response = await ApiClient.memoruGet(`/album/${albumId}`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async listAlbumContents({
    albumId,
    category,
    skip = 0,
    limit = 999,
  }: {
    albumId: number | string;
    category?: string;
    skip?: number;
    limit?: number;
  }): Promise<MultimediaListResponse> {
    try {
      const response = await ApiClient.memoruGet(`/album/${albumId}/multimedia`, {
        skip,
        limit,
        category,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async createAlbum({
    nurseryId,
    year,
    title,
    comment,
    nurseryClassIds,
    availableAfter,
    availableBefore,
  }: {
    nurseryId: number | string;
    year: number;
    title: string;
    comment: string | null;
    nurseryClassIds: Array<string | number>;
    availableAfter: string;
    availableBefore?: string | null;
  }): Promise<MultimediaListResponse> {
    try {
      const response = await ApiClient.memoruPost(`/album`, {
        nurseryId,
        year,
        title,
        comment: comment || '',
        nurseryClassIds,
        availableAfter,
        availableBefore,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateAlbum({
    albumId,
    year,
    title,
    comment,
    nurseryClassIds,
    availableAfter,
    availableBefore,
  }: {
    albumId: number | string;
    year: number;
    title: string;
    comment: string | null;
    nurseryClassIds: Array<string | number>;
    availableAfter: string;
    availableBefore?: string | null;
  }): Promise<MultimediaListResponse> {
    try {
      const response = await ApiClient.memoruPut(`/album/${albumId}`, {
        year,
        title,
        comment: comment || '',
        nurseryClassIds,
        availableAfter,
        availableBefore,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteAlbumContents({
    multimediaId,
  }: {
    multimediaId: number | string;
  }): Promise<MultimediaListResponse> {
    try {
      const response = await ApiClient.memoruDelete(`/multimedia/${multimediaId}`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async getUploadEndpoint({
    albumId,
    filename,
    date,
    category,
  }: {
    albumId: string | number;
    filename: string;
    date: string;
    category: string;
  }) {
    try {
      const response = await ApiClient.memoruPost(`/album/${albumId}/multimedia`, {
        filename,
        date,
        category,
      });
      return response as MultimediaResourceResponse;
    } catch (error) {
      throw error;
    }
  }

  public static async uploadFile(input: { url: string; file: File }) {
    axios.defaults.headers.common['Content-Type'] = input.file.type;

    const response = await axios({
      method: 'PUT',
      url: input.url,
      headers: {
        'Content-Type': input.file.type,
      },
      data: input.file,
    });

    if (response) {
      return response;
    }
  }
}
