import ApiClient from '@/api/ApiClient';
import {
  PrivateChannelAuthResponse,
  PushAuthResponse,
} from 'chaild-api/lib';

export default class ApiPusher {

  public static async publishPrivateChanel(input: { socketId: string, user_id: number }): Promise<PrivateChannelAuthResponse> {
    try {
      const response = await ApiClient.get(`/private-channel/auth`, input);
      if (response) {
        return response;
      }
      throw response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchPushAuth(): Promise<PushAuthResponse> {
    try {
      const response = await ApiClient.get(`/push/auth`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

}
