

































import Vue, { PropType } from 'vue';
import { MealResponse, NurseryChildResponse, MealStatus, MealStatusType } from 'chaild-api/lib';

import { when } from '@/service/Switch';

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true,
    },
    item: {
      type: String,
    },
  },
  data: () => {
    return {
      // btnLoading: false,

      showMenu: false,
      statusList: [
        { status: MealStatus.refill, title: 'おかわり' },
        { status: MealStatus.finished, title: '完食' },
        { status: MealStatus.unfinished, title: '少し残した' },
        { status: MealStatus.half, title: '半分食べた' },
        { status: MealStatus.little, title: '少し食べた' },
        { status: MealStatus.notEat, title: '食べなかった' },
        { status: MealStatus.notServed, title: '未提供' },
      ],
    };
  },
  computed: {},
  methods: {
    changeStatusItem(status: MealStatusType) {
      this.$emit('changeStatusItem', status, this.text)
    },
    showMealStatusText(status: MealStatusType): string {
      const temp = when(status)
        .on(
          v => v === MealStatus.refill,
          () => 'おかわり'
        )
        .on(
          v => v === MealStatus.finished,
          () => '完食'
        )
        .on(
          v => v === MealStatus.unfinished,
          () => '少し残した'
        )
        .on(
          v => v === MealStatus.half,
          () => '半分食べた'
        )
        .on(
          v => v === MealStatus.little,
          () => '少し食べた'
        )
        .on(
          v => v === MealStatus.notEat,
          () => '食べなかった'
        )
        .on(
          v => v === MealStatus.notServed,
          () => '未提供'
        )
        .otherwise(() => '');
      return temp;
    },
    showMealStatus(status: MealStatusType): string {
      const temp = when(status)
        .on(
          v => v === MealStatus.refill,
          () => 'refill'
        )
        .on(
          v => v === MealStatus.finished,
          () => 'finished'
        )
        .on(
          v => v === MealStatus.unfinished,
          () => 'unfinished'
        )
        .on(
          v => v === MealStatus.half,
          () => 'half'
        )
        .on(
          v => v === MealStatus.little,
          () => 'little'
        )
        .on(
          v => v === MealStatus.notEat,
          () => 'notEat'
        )
        .on(
          v => v === MealStatus.notServed,
          () => 'notServed'
        )
        .otherwise(() => '');
      return temp;
    },
  },
  mounted: async function() {},
});
