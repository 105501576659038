import { CreateNurserySleepRequest, UpdateNurserySleepRequest } from './../model/Request';
import ApiClient from '@/api/ApiClient';
import {
  ClassDiaryResponse,
  DiaryResponse,
  MealResponse,
  DefecationResponse,
  DailyReportResponse,
  NurseryDailyReportResponse,
  TemperatureResponse,
  MilkResponse,
  MilkChoicesResponse,
  SleepResponse,
} from 'chaild-api/lib/index';
import {
  CreateClassDiaryRequest, CreateMealRequest, UpdateClassDiaryRequest, UpdateMealRequest, CreateNurseryDefecationRequest, UpdateNurseryDefecationRequest, CreateNurseryMilkRequest, UpdateNurseryMilkRequest, CreateNurseryTemperatureRequest, UpdateNurseryTemperatureRequest
} from '@/model/Request';

export default class ApiDiaryReport {

  // ＝＝＝＝＝＝＝＝
  //  園児の日誌
  // ＝＝＝＝＝＝＝＝

  public static async fetchChildDiary(input: { childId: number, date: string }): Promise<DiaryResponse> {
    try {
      const response = await ApiClient.get(`/children/${input.childId}/diary`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async createChildDiary(input: { childId: number, date: string, content: string }): Promise<DiaryResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/diary`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateChildDiary(input: { childId: string, diaryId: string, content: string }): Promise<DiaryResponse> {
    try {
      const response = await ApiClient.put(`/children/${input.childId}/diary/${input.diaryId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // クラス日誌
  // ＝＝＝＝＝＝＝＝
  public static async fetchClassDiary(input: { classId: string, date: string }): Promise<ClassDiaryResponse> {
    try {
      const response = await ApiClient.get(`/classes/${input.classId}/diary`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async createClassDiary(input: CreateClassDiaryRequest): Promise<ClassDiaryResponse> {
    try {
      const response = await ApiClient.post(`/classes/${input.classId}/diary`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateClassDiary(input: UpdateClassDiaryRequest): Promise<ClassDiaryResponse> {
    try {
      const response = await ApiClient.put(`/classes/diary/${input.classDiaryId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  //  園児の記録
  // ＝＝＝＝＝＝＝＝

  public static async fetchChildReport(input: { childId: number, from?: string, to?: string }): Promise<DailyReportResponse[]> {
    try {
      const response = await ApiClient.get(`/children/${input.childId}/report`, input);
      return response.reports;
    } catch (error) {
      throw error;
    }
  }


  // ＝＝＝＝＝＝＝＝
  // 喫食
  // ＝＝＝＝＝＝＝＝

  public static async fetchClassMeal(input: { classId: string, date: string }): Promise<MealResponse[]> {
    try {
      const response = await ApiClient.get(`/classes/${input.classId}/meal`, input);
      return response.diaries;
    } catch (error) {
      throw error;
    }
  }

  public static async createClassMeal(input: CreateMealRequest): Promise<MealResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/meal`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateClassMeal(input: UpdateMealRequest): Promise<MealResponse> {
    try {
      const response = await ApiClient.put(`/children/${input.childId}/meal/${input.mealId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（園児の記録一覧（排便・ミルク・体温）を取得）
  // ＝＝＝＝＝＝＝＝
  public static async fetchChildOtherReport(input: { classId: string, date: string }): Promise<NurseryDailyReportResponse[]> {
    try {
      const response = await ApiClient.get(`/classes/${input.classId}/report`, input);
      return response.reports;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（排便）
  // ＝＝＝＝＝＝＝＝

  public static async saveChildDefecation(input: CreateNurseryDefecationRequest): Promise<DefecationResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/defecation`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateChildDefecation(input: UpdateNurseryDefecationRequest): Promise<DefecationResponse> {
    try {
      const response = await ApiClient.put(`/children/${input.childId}/defecation/${input.defecationId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChildDefecation(input: { childId: number, defecationId: number }) {
    try {
      const response = await ApiClient.delete(`/children/${input.childId}/defecation/${input.defecationId}`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（体温）
  // ＝＝＝＝＝＝＝＝

  public static async saveChildTemperature(input: CreateNurseryTemperatureRequest): Promise<TemperatureResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/temperature`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateChildTemperature(input: UpdateNurseryTemperatureRequest): Promise<TemperatureResponse> {
    try {
      const response = await ApiClient.put(`/children/${input.childId}/temperature/${input.temperatureId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChildTemperature(input: { childId: number, temperatureId: number }) {
    try {
      const response = await ApiClient.delete(`/children/${input.childId}/temperature/${input.temperatureId}`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（ミルク）
  // ＝＝＝＝＝＝＝＝

  public static async getChildMilkChoice(): Promise<MilkChoicesResponse> {
    try {
      const response = await ApiClient.get(`/milk/choices`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async saveChildMilk(input: CreateNurseryMilkRequest): Promise<MilkResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/milk`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateChildMilk(input: UpdateNurseryMilkRequest): Promise<MilkResponse> {
    try {
      const response = await ApiClient.put(`/children/${input.childId}/milk/${input.milkId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChildMilk(input: { childId: number, milkId: number }) {
    try {
      const response = await ApiClient.delete(`/children/${input.childId}/milk/${input.milkId}`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }
  // ＝＝＝＝＝＝＝＝
  // その他の記録（睡眠）
  // ＝＝＝＝＝＝＝＝

  public static async saveChildSleep(input: CreateNurserySleepRequest): Promise<SleepResponse> {
    try {
      const response = await ApiClient.post(`/sleep`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateChildSleep(input: UpdateNurserySleepRequest): Promise<SleepResponse> {
    try {
      const response = await ApiClient.put(`/sleep/${input.sleepId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChildSleep(sleepId: number) {
    try {
      const response = await ApiClient.delete(`/sleep/${sleepId}`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
