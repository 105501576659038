import { CreateNurserySleepRequest, UpdateNurserySleepRequest } from './../../model/Request';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiDiary from '@/api/ApiDiaryReport';

import { ClassDiaryResponse, DailyReportResponse, DefecationResponse, DiaryResponse, MealResponse, MilkResponse, NurseryChildResponse, NurseryDailyReportResponse, SleepResponse, TemperatureResponse } from 'chaild-api/lib/index';
import { GetChildrenResponse, MilkChoicesResponse } from '@/model/Response';
import { ChildrenRequest, CreateClassDiaryRequest, CreateMealRequest, CreateNurseryDefecationRequest, CreateNurseryMilkRequest, CreateNurseryTemperatureRequest, UpdateClassDiaryRequest, UpdateMealRequest, UpdateNurseryDefecationRequest, UpdateNurseryMilkRequest, UpdateNurseryTemperatureRequest } from '@/model/Request';


export interface DiaryReportState {
  childDiary: DiaryResponse | null;
  classDiary: ClassDiaryResponse | null;
  milkChoice: Array<{
    id: number | null;
    value: number | string;
  }>;
  classMeal: MealResponse[];
  classOtherReport: NurseryDailyReportResponse[];
  childReport: DailyReportResponse[];
}

@Module({ dynamic: true, store, name: 'diaryReport', namespaced: true })
class DiaryReportModule extends VuexModule implements DiaryReportState {

  public childDiary: DiaryResponse | null = null;
  public classDiary: ClassDiaryResponse | null = null;
  public milkChoice: Array<{
    id: number | null;
    value: number | string;
  }> = [];
  public classMeal: MealResponse[] = [];
  public classOtherReport: NurseryDailyReportResponse[] = [];
  public childReport: DailyReportResponse[] = [];

  // ＝＝＝＝＝＝＝＝
  //  園児の日誌
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async fetchChildDiary(input: { childId: number, date: string }) {
    try {
      const response = await ApiDiary.fetchChildDiary(input);
      this.setChildDiary(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChildDiary(childDiary: DiaryResponse) {
    this.childDiary = childDiary;
  }

  @Action({ rawError: true })
  public async createChildDiary(input: { childId: number, date: string, content: string }) {
    try {
      const response = await ApiDiary.createChildDiary(input);
      return response as DiaryResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateChildDiary(input: { childId: string, diaryId: string, content: string }) {
    try {
      const response = await ApiDiary.updateChildDiary(input);
      return response as DiaryResponse
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // クラス日誌
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async fetchClassDiary(input: { classId: string, date: string }) {
    try {
      const response = await ApiDiary.fetchClassDiary(input);
      this.setClassDiary(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setClassDiary(classDiary: ClassDiaryResponse) {
    this.classDiary = classDiary;
  }

  @Action({ rawError: true })
  public async createClassDiary(input: CreateClassDiaryRequest) {
    try {
      const response = await ApiDiary.createClassDiary(input);
      return response as ClassDiaryResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateClassDiary(input: UpdateClassDiaryRequest) {
    try {
      const response = await ApiDiary.updateClassDiary(input);
      return response as ClassDiaryResponse
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  //  園児の記録
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async fetchChildReport(input: { childId: number, from?: string, to?: string }) {
    try {
      const response = await ApiDiary.fetchChildReport(input);
      this.setChildReport(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChildReport(childReport: DailyReportResponse[]) {
    this.childReport = childReport;
  }

  // ＝＝＝＝＝＝＝＝
  // 喫食
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async fetchClassMeal(input: { classId: string, date: string }) {
    try {
      const response = await ApiDiary.fetchClassMeal(input);
      this.setClassMeal(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setClassMeal(classMeal: MealResponse[]) {
    this.classMeal = classMeal;
  }

  @Action({ rawError: true })
  public async createClassMeal(input: CreateMealRequest) {
    try {
      const response = await ApiDiary.createClassMeal(input);
      return response as MealResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateClassMeal(input: UpdateMealRequest) {
    try {
      const response = await ApiDiary.updateClassMeal(input);
      return response as MealResponse
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（園児の記録一覧（排便・ミルク・体温）を取得）
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async fetchChildOtherReport(input: { classId: string, date: string }) {
    try {
      const response = await ApiDiary.fetchChildOtherReport(input);
      this.setClassOtherReport(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setClassOtherReport(classOtherReport: NurseryDailyReportResponse[]) {
    this.classOtherReport = classOtherReport;
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（排便）
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async saveChildDefecation(input: CreateNurseryDefecationRequest) {
    try {
      const response = await ApiDiary.saveChildDefecation(input);
      return response as DefecationResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateChildDefecation(input: UpdateNurseryDefecationRequest) {
    try {
      const response = await ApiDiary.updateChildDefecation(input);
      return response as DefecationResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChildDefecation(input: { childId: number, defecationId: number }) {
    try {
      const response = await ApiDiary.deleteChildDefecation(input);
      return response
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（体温）
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async saveChildTemperature(input: CreateNurseryTemperatureRequest) {
    try {
      const response = await ApiDiary.saveChildTemperature(input);
      return response as TemperatureResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateChildTemperature(input: UpdateNurseryTemperatureRequest) {
    try {
      const response = await ApiDiary.updateChildTemperature(input);
      return response as TemperatureResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChildTemperature(input: { childId: number, temperatureId: number }) {
    try {
      const response = await ApiDiary.deleteChildTemperature(input);
      return response
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // その他の記録（ミルク）
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async getChildMilkChoice() {
    try {
      const response = await ApiDiary.getChildMilkChoice();
      this.setChildMilkChoice(response);
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChildMilkChoice(milkChoice: MilkChoicesResponse) {
    this.milkChoice = milkChoice.amountIds;
    this.milkChoice.unshift({
      id: null,
      value: '不明',
    });
  }

  @Action({ rawError: true })
  public async saveChildMilk(input: CreateNurseryMilkRequest) {
    try {
      const response = await ApiDiary.saveChildMilk(input);
      return response as MilkResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateChildMilk(input: UpdateNurseryMilkRequest) {
    try {
      const response = await ApiDiary.updateChildMilk(input);
      return response as MilkResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChildMilk(input: { childId: number, milkId: number }) {
    try {
      const response = await ApiDiary.deleteChildMilk(input);
      return response
    } catch (error) {
      throw error;
    }
  }


  // ＝＝＝＝＝＝＝＝
  // その他の記録（ミルク）
  // ＝＝＝＝＝＝＝＝

  @Action({ rawError: true })
  public async saveChildSleep(input: CreateNurserySleepRequest) {
    try {
      const response = await ApiDiary.saveChildSleep(input);
      return response as SleepResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async updateChildSleep(input: UpdateNurserySleepRequest) {
    try {
      const response = await ApiDiary.updateChildSleep(input);
      return response as SleepResponse
    } catch (error) {
      throw error;
    }
  }

  @Action({ rawError: true })
  public async deleteChildSleep(sleepId: number) {
    try {
      const response = await ApiDiary.deleteChildSleep(sleepId);
      return response
    } catch (error) {
      throw error;
    }
  }

}
export const diaryReportModule = getModule(DiaryReportModule);
