

































































import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

import { chatModule } from '@/store/dataModules/chatModule';
import { when } from '@/service/Switch';

// model
import { VForm } from '@/model/Vuetify/VForm';
import { AbsenceReason, AbsenceReasonType, NurseryAttendanceResponse } from 'chaild-api/lib';

interface DataType {
  deleteLoading: boolean;
  deleteDialog: boolean;
  absence: NurseryAttendanceResponse | null;
}

export default Vue.extend({
  name: 'AbsenceModal',

  props: {
    chatsAbsence: {
      type: Array as PropType<NurseryAttendanceResponse[]>,
      default: [],
    },
    childId: {
      type: Number,
      default: NaN,
    },
    date: {
      type: Object as PropType<any>,
      default: {},
    },
    nurseryId: {
      type: Number,
      default: NaN,
    },
  },

  computed: {},

  data: (): DataType => ({
    deleteLoading: false,
    deleteDialog: false,
    absence: null,
  }),

  watch: {
    deleteDialog: {
      async handler(val: boolean) {
        if (!val) {
          this.absence = null;
        }
      },
      deep: true,
    },
  },

  methods: {
    showReason(reason: AbsenceReasonType): string {
      const temp = when(reason)
        .on(
          v => v === AbsenceReason.fever,
          () => '発熱'
        )
        .on(
          v => v === AbsenceReason.personal,
          () => '家庭の事情'
        )
        .on(
          v => v === AbsenceReason.infStrep,
          () => '溶連菌感染症'
        )
        .on(
          v => v === AbsenceReason.infMycoplasma,
          () => 'マイコプラズマ肺炎'
        )
        .on(
          v => v === AbsenceReason.infHFMD,
          () => '手足口病'
        )
        .on(
          v => v === AbsenceReason.infSlappedCheek,
          () => '伝染性紅斑（りんご病）'
        )
        .on(
          v => v === AbsenceReason.infStomach,
          () => 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）'
        )
        .on(
          v => v === AbsenceReason.infHerpangina,
          () => 'ヘルパンギーナ'
        )
        .on(
          v => v === AbsenceReason.infRS,
          () => 'RSウィルス感染症'
        )
        .on(
          v => v === AbsenceReason.infZoster,
          () => '帯状疱疹'
        )
        .on(
          v => v === AbsenceReason.infRoseola,
          () => '突発性発しん'
        )
        .on(
          v => v === AbsenceReason.infImpetigo,
          () => '伝染性膿痂疹（とびひ）'
        )
        .on(
          v => v === AbsenceReason.covid19,
          () => '新型コロナウイルス'
        )
        .on(
          v => v === AbsenceReason.others,
          () => 'その他'
        )
        .otherwise(() => '');
      return temp;
    },
    close() {
      this.$emit('close');
    },
    isDeleteAbsence(absence: NurseryAttendanceResponse) {
      this.absence = absence;
      this.deleteDialog = true;
    },
    async deleteAbsence() {
      try {
        this.deleteLoading = true;
        if (this.absence) {
          await chatModule.deleteChatsAbsence({
            childId: this.childId,
            date: this.absence.date,
          });
          this.$emit('posted');
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$emit('close');
        this.deleteLoading = false;
      }
    },
  },

  async mounted() {},
});
