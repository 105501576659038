import { SearchDiaryRequest } from './../model/Request';
import { ChildrenDiaryResponse } from './../model/Response';
import ApiClient from '@/api/ApiClient';
import { ChangeNurseryClassRequest } from '@/model/Request';
import {
  ChildInfoResponse,
  NurseryChildResponse,
  NurseryClassResponse,
} from 'chaild-api/lib/index';
import { GetChildrenResponse } from '@/model/Response';
import { ChildrenRequest } from '@/model/Request';

export default class ApiAttendance {

  // 児童の一覧取得
  public static async getChildren(input: ChildrenRequest): Promise<GetChildrenResponse> {
    try {
      const response = await ApiClient.get(`/children`, input);
      return response
    } catch (error) {
      throw error;
    }
  }

  // 児童の詳細取得
  public static async getChild(childId: number): Promise<NurseryChildResponse> {
    try {
      const response = await ApiClient.get(`/children/${childId}`, null);
      return response
    } catch (error) {
      throw error;
    }
  }

  // 個人日誌検索
  public static async getChildDiary(input: SearchDiaryRequest): Promise<ChildrenDiaryResponse> {
    try {
      const response = await ApiClient.get(`/children/diary/search`, input);
      return response
    } catch (error) {
      throw error;
    }
  }

  // 児童表取得
  public static async getChildInfo(childId: number): Promise<ChildInfoResponse> {
    try {
      const response = await ApiClient.get(`/children-info/${childId}`, null);
      return response
    } catch (error) {
      throw error;
    }
  }
}
