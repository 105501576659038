





import Vue from 'vue';
import CommunicationNewMessageContainer from '@/container/communication/CommunicationNewMessageContainer.vue';

export default Vue.extend({
  components: {
    CommunicationNewMessageContainer,
  },
});
