







import Vue from 'vue';
import ClassRecordsContainer from '@/container/records/ClassRecordsContainer.vue';

export default Vue.extend({
  components: {
    ClassRecordsContainer,
  },
});
