


















































import Vue from 'vue';

import DatePager from '@/components/molecules/DatePager.vue';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import LocalDataService from '@/service/LocalDataService';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import { childrenModule } from '@/store/dataModules/childrenModule';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';
import { DiaryResponse, NurseryChildResponse, NurseryClassResponse } from 'chaild-api/lib';
dayjs.locale(ja);
export default Vue.extend({
  components: {
    DatePager,
  },
  data: () => {
    return {
      btnLoading: false,
      contentLoading: false,
      date: '' as any,
      tabIndex: 0,
      time: '',
      content: '特記事項なし',
      childDiary: null as any,
      isRecordDialog: false,
      otherRecord: '',
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
      classId: 0,
      childId: 0,
    };
  },
  computed: {
    clas(): NurseryClassResponse | null {
      if (nurseryModule.clas) {
        return nurseryModule.clas;
      }
      return null;
    },
    child(): NurseryChildResponse | null {
      if (childrenModule.child) {
        return childrenModule.child;
      }
      return null;
    },
  },
  watch: {
    date: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    routerBack() {
      const routes = window.history.length
      console.log(routes)
      if(routes > 1) {
        this.$router.back()
      } else {
        const classId = this.$route.params.classId;
        this.$router.push(`/record/class/${classId}`);
      }
    },
    addDay() {
      this.date = this.date.add(1, 'd');
    },
    subtractDay() {
      this.date = this.date.subtract(1, 'd');
    },
    saveContent: async function() {
      if (this.content) {
        this.btnLoading = true;
        if (this.childDiary && !this.childDiary.error) {
          try {
            const response = await diaryReportModule.updateChildDiary({
              childId: this.childId.toString(10),
              diaryId: this.childDiary.diaryId.toString(10),
              content: this.content,
            });
            this.childDiary = response;
            this.snackBarText = '保存しました';
            this.snackbar = true;
          } catch {}
        } else {
          try {
            const response = await diaryReportModule.createChildDiary({
              childId: this.childId,
              date: this.date.format('YYYY-MM-DD'),
              content: this.content,
            });
            this.childDiary = response;
            this.snackBarText = '保存しました';
            this.snackbar = true;
          } catch {}
        }
        this.btnLoading = false;
      }
    },
    init: async function() {
      try {
        // this.contentLoading = true
        const user = LocalDataService.getUser();
        const classId = this.$route.params.classId;
        const childId = this.$route.params.childId;
        this.classId = parseInt(classId, 10);
        this.childId = parseInt(childId, 10);
        if (user) {
          let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
          if (!nurserySchool) {
            nurserySchool = user.nurserySchools[0];
          }
          const nurseryId = nurserySchool.nurseryId;
          Promise.all([
            nurseryModule.getNurseryClassDetail({
              nurseryId,
              classId: this.classId,
            }),
            childrenModule.getChild(this.childId),
          ]);
          await diaryReportModule.fetchChildDiary({
            childId: this.childId,
            date: this.date.format('YYYY-MM-DD'),
          });
          this.content = '特記事項なし'
          if (diaryReportModule.childDiary) {
            this.childDiary = await diaryReportModule.childDiary;
            if (diaryReportModule.childDiary.content) {
              this.content = diaryReportModule.childDiary.content;
            }
          }
        }
      } catch {
      } finally {
        // this.contentLoading = false
      }
    },
  },
  async created() {
    const date = this.$route.query.date as string
    if (date) {
      const temp = dayjs(date);
      this.date = temp
    } else {
      this.date = dayjs();
    }
  },
  async mounted() {
    // const localText = LocalDataService.getDiaryText();
    // if (localText) {
    //   this.content = localText;
    // }
    this.init();
  },
  beforeDestroy() {
    childrenModule.refreshChild();
    nurseryModule.refreshClass();
    // if (this.content && !this.childDiary.content) {
    //   LocalDataService.setDiaryText(this.content);
    // } else {
    //   LocalDataService.removeDiaryText();
    // }
  },
});
