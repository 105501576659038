import ApiClient from '@/api/ApiClient';
import {
  NurseryAttendanceResponse,
} from 'chaild-api/lib/index';
import {
  GetAttendanceSearchRequest,
  GetAttendanceRequest,
  PostAttendanceRequest,
  PostAttendanceAttendRequest,
  PostAttendanceLeaveRequest,
  ChangeAttendanceRequest
} from '@/model/Request';
import {
  GetAttendanceSearchResponse,
  MessageResponse
} from '@/model/Response';


export default class ApiAttendance {

  // 出欠管理の検索
  public static async searchAttendance(input: GetAttendanceSearchRequest): Promise<GetAttendanceSearchResponse> {
    try {
      const response = await ApiClient.get(`/attendance/search`, input);
      return response
    } catch (error) {
      throw error;
    }
  }

  // 園児の登園・降園記録を取得
  public static async getAttendance(input: GetAttendanceRequest): Promise<NurseryAttendanceResponse> {
    try {
      if (input.date) {
        const response = await ApiClient.get(`/children/${input.childId}/attendance`, { date: input.date });
        return response.data
      } else {
        const response = await ApiClient.get(`/children/${input.childId}/attendance`, null);
        return response
      }
    } catch (error) {
      throw error;
    }
  }

  // 保育園の職員の方が, 登園・降園記録を作成
  public static async postAttendance(input: PostAttendanceRequest): Promise<NurseryAttendanceResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/attendance`, { date: input.date, attendedAt: input.attendedAt, leftAt: input.leftAt });
      return response
    } catch (error) {
      throw error;
    }
  }

  // 園児が操作して, 園児の登園記録を作成
  public static async postAttendanceAttend(input: PostAttendanceAttendRequest): Promise<NurseryAttendanceResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/attendance/attend`, { date: input.date, attendedAt: input.attendedAt, temperatureId: input.temperatureId });
      return response
    } catch (error) {
      throw error;
    }
  }

  // 園児が操作して, 園児の降園記録を作成
  public static async postAttendanceLeave(input: PostAttendanceLeaveRequest): Promise<NurseryAttendanceResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/attendance/leave`, { date: input.date, leftAt: input.leftAt });
      return response
    } catch (error) {
      throw error;
    }
  }

  // 園児の登園・降園記録を削除
  public static async deleteAttendance(input: ChangeAttendanceRequest): Promise<MessageResponse> {
    try {
      const response = await ApiClient.delete(`/children/${input.childId}/attendance/${input.attendanceId}`, null);
      return response
    } catch (error) {
      throw error;
    }
  }

  // 園児の登園・降園記録を更新
  public static async putAttendance(input: ChangeAttendanceRequest): Promise<NurseryAttendanceResponse> {
    try {
      const response = await ApiClient.put(`/children/${input.childId}/attendance/${input.attendanceId}`, null);
      return response
    } catch (error) {
      throw error;
    }
  }
}
