import { ChildrenDiaryResponse } from './../../model/Response';
import { SearchDiaryRequest } from './../../model/Request';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiChildren from '@/api/ApiChildren';

import { ChildInfoResponse, NurseryChildResponse } from 'chaild-api/lib/index';
import { GetChildrenResponse } from '@/model/Response';
import { ChildrenRequest } from '@/model/Request';


export interface ChildrenState {
  children: GetChildrenResponse | null;
  child: NurseryChildResponse | null;
  diary: ChildrenDiaryResponse | null;
  childInfo: ChildInfoResponse | null;
}

@Module({ dynamic: true, store, name: 'children', namespaced: true })
class ChildrenModule extends VuexModule implements ChildrenState {

  public children: GetChildrenResponse | null = null;
  public child: NurseryChildResponse | null = null;
  public diary: ChildrenDiaryResponse | null = null;
  public childInfo: ChildInfoResponse | null = null;

  @Action({ rawError: true })
  public async getChildren(input: ChildrenRequest) {
    try {
      const response = await ApiChildren.getChildren(input);
      this.setChildren(response);
      return response as GetChildrenResponse;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChildren(children: GetChildrenResponse) {
    this.children = children;
  }

  @Action({ rawError: true })
  public async getChild(childId: number) {
    try {
      const response = await ApiChildren.getChild(childId);
      this.setChild(response);
      return response as NurseryChildResponse;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChild(child: NurseryChildResponse) {
    this.child = child;
  }

  @Action({ rawError: true })
  public async getChildDiary(input: SearchDiaryRequest) {
    try {
      const response = await ApiChildren.getChildDiary(input);
      this.setChildDiary(response);
      return response as ChildrenDiaryResponse;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChildDiary(diary: ChildrenDiaryResponse) {
    this.diary = diary;
  }

  @Action({ rawError: true })
  public async getChildInfo(childId: number) {
    try {
      const response = await ApiChildren.getChildInfo(childId);
      this.setChildInfo(response);
      return response as ChildInfoResponse;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChildInfo(childInfo: ChildInfoResponse) {
    this.childInfo = childInfo;
  }

  @Mutation
  public refreshChild() {
    this.child = null;
  }

  @Mutation
  public refreshChildren() {
    this.children = null;
  }
}
export const childrenModule = getModule(ChildrenModule);
