





























import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import {
  AbsenceReason,
  AbsenceReasonType,
} from 'chaild-api/lib';
import 'dayjs/locale/ja';
import { when } from '@/service/Switch';

dayjs.locale('ja');

// model
import { VForm } from '@/model/Vuetify/VForm';
import { NurseryAttendanceResponse } from 'chaild-api/lib';
import { AbsenceChildType } from '@/model/Response';

interface DataType {
  absenceId: number;
}

export default Vue.extend({
  name: 'AbsenceModal',

  props: {
    chatsAbsence: {
      type: Array as PropType<AbsenceChildType[]>,
      default: [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  data: (): DataType => ({
    absenceId: NaN,
  }),

  watch: {
    deleteDialog: {
      async handler(val: boolean) {
        if (!val) {
          this.absenceId = NaN;
        }
      },
      deep: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
    showReason(reason: AbsenceReasonType): string {
      const temp = when(reason)
        .on(
          v => v === AbsenceReason.fever,
          () => '発熱'
        )
        .on(
          v => v === AbsenceReason.personal,
          () => '家庭の事情'
        )
        .on(
          v => v === AbsenceReason.infStrep,
          () => '溶連菌感染症'
        )
        .on(
          v => v === AbsenceReason.infMycoplasma,
          () => 'マイコプラズマ肺炎'
        )
        .on(
          v => v === AbsenceReason.infHFMD,
          () => '手足口病'
        )
        .on(
          v => v === AbsenceReason.infSlappedCheek,
          () => '伝染性紅斑（りんご病）'
        )
        .on(
          v => v === AbsenceReason.infStomach,
          () => 'ウイルス性胃腸炎（ノロ、ロタ、アデノ等）'
        )
        .on(
          v => v === AbsenceReason.infHerpangina,
          () => 'ヘルパンギーナ'
        )
        .on(
          v => v === AbsenceReason.infRS,
          () => 'RSウィルス感染症'
        )
        .on(
          v => v === AbsenceReason.infZoster,
          () => '帯状疱疹'
        )
        .on(
          v => v === AbsenceReason.infRoseola,
          () => '突発性発しん'
        )
        .on(
          v => v === AbsenceReason.infImpetigo,
          () => '伝染性膿痂疹（とびひ）'
        )
        .on(
          v => v === AbsenceReason.covid19,
          () => '新型コロナウイルス'
        )
        .on(
          v => v === AbsenceReason.others,
          () => 'その他'
        )
        .otherwise(() => '');
      return temp;
    },
  },

  async mounted() {},
});
