






























import Vue from 'vue';
export default Vue.extend({

  props: {
    btnSendLoading: {
      type: Boolean,
      default: false
    },
    btnDraftLoading: {
      type: Boolean,
      default: false
    },
    btnReadyLoading: {
      type: Boolean,
      default: false
    },
    inputText: {
      type: String
    },
    isChatCreateUserMe: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    disabled(): Boolean {
      if(this.inputText && this.isChatCreateUserMe) {
        return false
      }
      return true
    }
  },

  methods: {
    saveDraft() {
      this.$emit('saveDraft');
    },
    saveSendReady() {
      this.$emit('saveSendReady');

    },
    sendMessage() {
      this.$emit('sendMessage');

    }
  }
});
