








































































































import Vue from 'vue';

import { PostMeRequest } from '@/model/Request';
import { meModule } from '@/store/dataModules/meModule';
import { auth0Module } from '@/store/dataModules/auth0Module';
import LocalDataService from '@/service/LocalDataService';
import { MeNurserySchoolResponse } from 'chaild-api/lib';

interface DataType {
  postMeRequest: PostMeRequest;
}

export default Vue.extend({
  data: () => ({
    absentInput: NaN,
    pickupInput: NaN,
    chatInput: NaN,
    workflowCreateInput: NaN,
    workflowApprovedInput: NaN,
    workflowRejectedInput: NaN,
    btnLoading: false,
    selectNursery: NaN,
    timeout: 2000,
    snackBarText: '',
    snackbar: false,
    settingList: [
      { value: 0, text: '両方' },
      { value: 1, text: 'PUSHのみ' },
      { value: 2, text: 'メールのみ' },
      { value: 3, text: '通知なし' },
    ],
  }),
  computed: {
    nurserySchool(): MeNurserySchoolResponse | null {
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        return nurserySchool;
      }
      return null;
    },
    nurserySchools(): MeNurserySchoolResponse[] {
      const user = LocalDataService.getUser();
      if (user) {
        const nurserySchools = user.nurserySchools;
        return nurserySchools;
      }
      return [];
    },
  },

  methods: {
    async changeAbsent() {
      if (this.absentInput > -1 && this.nurserySchool) {
        try {
          await meModule.putMe({
            notifications: [
              {
                nurseryId: this.nurserySchool.nurseryId,
                key: 'absentReported',
                value: this.absentInput,
              },
            ],
          });
        } catch {
        } finally {
        }
      }
    },
    async changePickup() {
      if (this.pickupInput > -1 && this.nurserySchool) {
        try {
          await meModule.putMe({
            notifications: [
              {
                nurseryId: this.nurserySchool.nurseryId,
                key: 'pickupReported',
                value: this.pickupInput,
              },
            ],
          });
        } catch {
        } finally {
        }
      }
    },
    async changeChat() {
      if (this.chatInput > -1 && this.nurserySchool) {
        try {
          await meModule.putMe({
            notifications: [
              {
                nurseryId: this.nurserySchool.nurseryId,
                key: 'chatReceived',
                value: this.chatInput,
              },
            ],
          });
        } catch {
        } finally {
        }
      }
    },
    async changeWorkflowCreate() {
      if (this.workflowCreateInput > -1 && this.nurserySchool) {
        try {
          await meModule.putMe({
            notifications: [
              {
                nurseryId: this.nurserySchool.nurseryId,
                key: 'workflowCreated',
                value: this.workflowCreateInput,
              },
            ],
          });
        } catch {
        } finally {
        }
      }
    },
    async changeWorkflowApproved() {
      if (this.workflowApprovedInput > -1 && this.nurserySchool) {
        try {
          await meModule.putMe({
            notifications: [
              {
                nurseryId: this.nurserySchool.nurseryId,
                key: 'workflowApproved',
                value: this.workflowApprovedInput,
              },
            ],
          });
        } catch {
        } finally {
        }
      }
    },
    async changeWorkflowRejected() {
      if (this.workflowRejectedInput > -1 && this.nurserySchool) {
        try {
          await meModule.putMe({
            notifications: [
              {
                nurseryId: this.nurserySchool.nurseryId,
                key: 'workflowRejected',
                value: this.workflowRejectedInput,
              },
            ],
          });
        } catch {
        } finally {
        }
      }
    },
  },

  mounted() {
    if (this.nurserySchool) {
      this.absentInput = this.nurserySchool.notifications.absentReported;
      this.pickupInput = this.nurserySchool.notifications.pickupReported;
      this.chatInput = this.nurserySchool.notifications.chatReceived;
      this.workflowCreateInput = this.nurserySchool.notifications.workflowCreated;
      this.workflowApprovedInput = this.nurserySchool.notifications.workflowApproved;
      this.workflowRejectedInput = this.nurserySchool.notifications.workflowRejected;
    }
  },
  beforeDestroy() {
    meModule.setTabIndex('setting')
  },
});
