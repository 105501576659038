
















































































import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

// model
// import { VForm } from '@/model/vuetify/VForm';
import { MilkResponse, NurseryMilkResponse, MilkChoicesResponse, SleepResponse } from 'chaild-api/lib';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';

export default Vue.extend({
  name: 'MilkModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
    sleepTemp: {
      type: Object as PropType<SleepResponse>,
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form() {
      return (this.$refs as any).post_milk_form;
    },
  },

  watch: {
    isOpen: {
      handler(val: boolean) {
        if (val) {
          this.from = '';
          this.to = '';
        }
      },
      deep: true,
    },
  },

  data: () => ({
    from: '',
    to: '',
    btnLoading: false,
    isFromTimePicker: false,
    isToTimePicker: false,
  }),

  methods: {
    allowedStep: (m: any) => m % 5 === 0,
    changeFrom() {
      this.$emit('changeFrom', this.from);
    },
    changeTo() {
      this.$emit('changeTo', this.to);
    },
    formatTime(time: string) {
      const nowTime = dayjs(time).format('HH:mm');
      return nowTime;
    },
  },
});
