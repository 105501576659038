














































import Vue from 'vue';

import { PostMeRequest } from '@/model/Request';
import { meModule } from '@/store/dataModules/meModule';
import { auth0Module } from '@/store/dataModules/auth0Module';
import LocalDataService from '@/service/LocalDataService';
import { MeNurserySchoolResponse } from 'chaild-api/lib';

interface DataType {
  postMeRequest: PostMeRequest;
}

export default Vue.extend({
  data: () => ({
    btnLoading: false,
    selectNursery: NaN,
    timeout: 2000,
    snackBarText: '',
    snackbar: false,
  }),
  computed: {
    nurserySchool(): MeNurserySchoolResponse | null {
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        return nurserySchool;
      }
      return null;
    },
    nurserySchools(): MeNurserySchoolResponse[] {
      const user = LocalDataService.getUser();
      if (user) {
        const nurserySchools = user.nurserySchools;
        return nurserySchools;
      }
      return [];
    },
  },

  methods: {
    async changeNursery() {
      // auth0Module.logout();
      console.log(this.selectNursery);
      if (this.selectNursery && this.nurserySchool) {
        try {
          this.btnLoading = true;
          await meModule.putMe({
            defaultNurseryId: this.selectNursery
          });
          this.snackBarText = '変更しました';
          this.snackbar = true;
        } catch {
        } finally {
          this.btnLoading = false;
        }
      }
      setTimeout(this.toHome, 1000);
    },
    toHome() {
      this.$router.push('/')
    }
  },
  beforeDestroy() {
    meModule.setTabIndex('setting')
  },
});
