import { PostChatsAbsenceRequest, DeleteChatsAbsenceRequest } from './../model/Request';
import ApiClient from '@/api/ApiClient';
import {
  NurseryAttendanceResponse,
  ChatCommentResponse,
  ChatListResponse,
  ChatResponse,
  ChatSummaryResponse,
  GroupChatResponse,
} from 'chaild-api/lib/index';
import {
  SendChatRequest,
  SaveChatDraftRequest,
  SaveChatReadyRequest,
  SendGroupChatRequest,
  SendGroupChatReadyRequest,
  UpdateGroupChatReadyRequest
} from '@/model/Request';

export default class ApiChat {

  // ＝＝＝＝＝＝＝＝
  // 個別チャット
  // ＝＝＝＝＝＝＝＝

  public static async fetchChat(input: { childId: number, date: string }): Promise<ChatListResponse> {
    try {
      const response = await ApiClient.get(`/children/${input.childId}/chats`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchChatContent(input: { chatId: number, date: string }): Promise<ChatResponse> {
    try {
      const response = await ApiClient.get(`/chats/${input.chatId}`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async sendChat(input: SendChatRequest): Promise<ChatResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/chats`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async confirmChat(input: { chatId: number, childId: number}): Promise<ChatResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/chats/${input.chatId}/confirm`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  
  public static async saveComment(input: { childId: number, date?: string, comment: string }): Promise<ChatCommentResponse> {
    try {
      const response = await ApiClient.put(`/children/${input.childId}/chats/comment`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChat(input: { childId: number, chatId: number }) {
    try {
      const response = await ApiClient.delete(`/children/${input.childId}/chats/${input.chatId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // 送信準備
  // ＝＝＝＝＝＝＝＝
  
  public static async fetchChatReady(nurseryId: number): Promise<ChatResponse[]> {
    try {
      console.log(nurseryId)
      const response = await ApiClient.get(`/children/chats/ready`, { nurseryId });
      return response.chats;
    } catch (error) {
      throw error;
    }
  }

  public static async sendChatReady(chatIds: string[]): Promise<ChatResponse> {
    try {
      const response = await ApiClient.post(`/children/chats/ready`, { chatIds });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChatReady(chatIds: string) {
    try {
      const response = await ApiClient.delete(`/children/chats/ready`, { chatIds });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async saveChatReady(input: SaveChatReadyRequest): Promise<ChatResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/chats/ready`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // 下書き
  // ＝＝＝＝＝＝＝＝

  public static async fetchChatDraft(nurseryId: number): Promise<ChatResponse[]> {
    try {
      const input = {
        nurseryId,
      }
      const response = await ApiClient.get(`/children/chats/draft`, { nurseryId });
      return response.drafts;
    } catch (error) {
      throw error;
    }
  }

  public static async saveChatDraft(input: SaveChatDraftRequest): Promise<ChatResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/chats/draft`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChatDraft(chatIds: string) {
    try {
      const response = await ApiClient.delete(`/children/chats/draft`, { chatIds });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // 一斉送信
  // ＝＝＝＝＝＝＝＝

  public static async sendAllChat(input: SendGroupChatRequest): Promise<GroupChatResponse> {
    try {
      const response = await ApiClient.post(`/group-chats`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async getAllChatDraft(nurseryId: number): Promise<GroupChatResponse[]> {
    try {
      const response = await ApiClient.get(`/group-chats/draft`, { nurseryId });
      return response.groupChats;
    } catch (error) {
      throw error;
    }
  }

  public static async saveAllChatDraft(input: SendGroupChatRequest): Promise<GroupChatResponse> {
    try {
      const response = await ApiClient.post(`/group-chats/draft`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteAllChatDraft(groupChatIds: string) {
    try {
      const response = await ApiClient.delete(`/group-chats/draft`, { groupChatIds });
      return response;
    } catch (error) {
      throw error;
    }
  }


  public static async getAllChatReady(nurseryId: number): Promise<GroupChatResponse[]> {
    try {
      const response = await ApiClient.get(`/group-chats/ready`, { nurseryId });
      return response.groupChats;
    } catch (error) {
      throw error;
    }
  }

  public static async saveAllChatReady(input: SendGroupChatReadyRequest): Promise<GroupChatResponse> {
    try {
      const response = await ApiClient.post(`/group-chats/ready`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteAllChatReady(groupChatIds: string){
    try {
      const response = await ApiClient.delete(`/group-chats/ready`, { groupChatIds });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async updateAllChatReady(input: UpdateGroupChatReadyRequest) {
    try {
      const response = await ApiClient.put(`/group-chats/ready/${input.groupChatId}`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async getChatSummary(input: { nurseryId: number, date: string }): Promise<ChatSummaryResponse> {
    try {
      const response = await ApiClient.get(`/chats/summary`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // ＝＝＝＝＝＝＝＝
  // 欠席連絡
  // ＝＝＝＝＝＝＝＝

  public static async getChatsAbsence(childId: number): Promise<NurseryAttendanceResponse[]> {
    try {
      const response = await ApiClient.get(`/children/${childId}/absence`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async postChatsAbsence(input: PostChatsAbsenceRequest): Promise<NurseryAttendanceResponse> {
    try {
      const response = await ApiClient.post(`/children/${input.childId}/absence`, input);
      return response
    } catch (error) {
      throw error;
    }
  }

  public static async deleteChatsAbsence(input: DeleteChatsAbsenceRequest): Promise<NurseryAttendanceResponse> {
    try {
      const response = await ApiClient.delete(`/children/${input.childId}/absence/${input.date} `, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

}
