
























































import Vue, { PropType } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { DefecationStatus, DefecationStatusType, NurseryDefecationResponse } from 'chaild-api/lib';
dayjs.locale('ja');
import rules from '@/service/ValidRules';

// model
// import { VForm } from '@/model/vuetify/VForm';

export default Vue.extend({
  name: 'DefecationModal',

  props: {
    childId: {
      type: Number,
      default: NaN,
    },
    selectedDate: {
      type: String,
      default: dayjs().format('YYYY-MM-DD'),
    },
    defecationTemp: {
      type: Object as PropType<NurseryDefecationResponse>,
    },
  },

  computed: {
    rules() {
      return rules;
    },
    form() {
      return (this.$refs as any).post_defecation_form;
    },
  },

  watch: {},

  data: () => ({
    recordedAt: '',
    state: '',
    btnLoading: false,
    isRecordetAtPicker: false,
    choices: [
      {
        text: '硬い',
        value: DefecationStatus.hard,
      },
      {
        text: '普通',
        value: DefecationStatus.normal,
      },
      {
        text: '軟らかい',
        value: DefecationStatus.soft,
      },
    ],
  }),

  methods: {
    allowedStep: (m: any) => m % 5 === 0,
    changeRecordedAt() {
      this.$emit('changeRecordedAt', this.recordedAt);
    },
    changeState() {
      this.$emit('changeState', this.state);
    },
    showDefecationStatus(state: DefecationStatusType) {
      if (state === 'hard') {
        return '硬い';
      } else if (state === 'normal') {
        return '普通';
      } else if (state === 'soft') {
        return '柔らかい';
      }
    },
    formatTime(time: string) {
      const nowTime = dayjs(time).format('HH:mm');
      return nowTime;
    },
  },
});
