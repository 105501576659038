











































import Vue, { PropType } from 'vue';

import { NurseryChildResponse } from 'chaild-api/lib/index';
import { GetAttendanceSearchResponse } from '@/model/Response';

import LocalDataService from '@/service/LocalDataService';
import dayjs from 'dayjs';
import { chatModule } from '@/store/dataModules/chatModule';

import { ChatSummaryResponse } from 'chaild-api/lib/index';

export default Vue.extend({
  data: () => {
    return {};
  },
  props: {
    child: {
      type: Object as PropType<NurseryChildResponse>,
      required: true,
    },
    attendanceClass: {
      type: Object as PropType<GetAttendanceSearchResponse>,
      required: true,
    },
    chatSummary: {
      type: Object as PropType<any>,
    },
  },
  computed: {
    childChatSummary() {
      const find = this.chatSummary.childrenAttendance.find(
        childAttendance => childAttendance.child.childId === this.child.childId
      );
      if (find) {
        return find;
      } 
      return null
    },
  },
  methods: {
    childAttendance(childId?: number) {
      if (childId) {
        const find = this.attendanceClass.attendances.find(
          attendance => attendance.child.childId === childId
        );
        return find;
      }
    },
  },
  async mounted() {},
});
