import ApiClient from '@/api/ApiClient';
import { ChangeNurseryClassRequest } from '@/model/Request';
import {
  NurseryClassResponse,
} from 'chaild-api/lib/index';
import { NurseryUserListResponse, NurseryUserResponse } from 'chaild-api/src/component/nursery/user/types';
import { getFiscalYear } from '@/service/CommonUtils'


export default class ApiNursery {

  // 保育園内のクラスを全て取得する
  public static async getNurseryUser(nurseryId: number): Promise<NurseryUserListResponse> {
    try {
      const response = await ApiClient.get(`/nursery/${nurseryId}/user`, null);
      return response
    } catch (error) {
      throw error;
    }
  }

  // 保育園内のクラスを全て取得する
  public static async getNurseryClass(nurseryId: number, fiscalYear?: number): Promise<NurseryClassResponse[]> {
    try {
      const year = fiscalYear || getFiscalYear()
      const response = await ApiClient.get(`/nursery/${nurseryId}/classes`, { year });
      return response
    } catch (error) {
      throw error;
    }
  }

  // クラス情報取得
  public static async getNurseryClassDetail(input: { nurseryId: number, classId: number }): Promise<NurseryClassResponse> {
    try {
      const response = await ApiClient.get(`/nursery/${input.nurseryId}/classes/${input.classId}`, null);
      return response
    } catch (error) {
      throw error;
    }
  }

  // 保育園にクラスを追加
  public static async postNurseryClass(input: ChangeNurseryClassRequest): Promise<NurseryClassResponse> {
    try {
      const response = await ApiClient.post(`/nursery/${input.nurseryId}/classes`, { className: input.className, year: input.year });
      return response
    } catch (error) {
      throw error;
    }
  }

  // クラスを削除する
  public static async DeleteNurseryClass(input: { nurseryId: number, classId: number }): Promise<NurseryClassResponse> {
    try {
      const response = await ApiClient.delete(`/nursery/${input.nurseryId}/classes/${input.classId}`, null);
      return response
    } catch (error) {
      throw error;
    }
  }

  // クラス情報更新
  public static async putNurseryClass(input: ChangeNurseryClassRequest): Promise<NurseryClassResponse> {
    try {
      const response = await ApiClient.put(`/nursery/${input.nurseryId}/classes`, { className: input.className, year: input.year });
      return response
    } catch (error) {
      throw error;
    }
  }

}
