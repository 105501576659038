





import Vue from 'vue';
import CommunicationDraftContainer from '@/container/communication/CommunicationDraftContainer.vue';

export default Vue.extend({
  components: {
    CommunicationDraftContainer,
  },
});
