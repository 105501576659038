











import Vue from 'vue';
import JwtDecode from 'jwt-decode';
import { auth0Module } from '@/store/dataModules/auth0Module';
import LocalDataService from '@/service/LocalDataService';
import { meModule } from '@/store/dataModules/meModule';
import { Authorizer } from 'chaild-api/src/common/entity/aws/authorize';
import VueLoading from '@/components/pages/Loding.vue';

import { ErrorType } from '@/api/ApiClient';
import { MeResponse } from 'chaild-api/lib';
import { pusherModule } from './store/dataModules/pusherModule';

export default Vue.extend({
  name: 'App',

  components: {
    VueLoading,
  },

  data: () => ({
    routePath: '',
    loading: false,
    me: null as MeResponse | null,
  }),
  created: async function() {
    console.log('app created');
    this.loading = true;
    this.routePath = this.$route.path;
    try {
      // バージョン確認
      await meModule.fetchVersion();
      const localVersion = LocalDataService.getVersion();
      const current = meModule.currentVersion?.currentVersion;
      if (current) {
        const currentVersion = current.slice(1);
        if (localVersion) {
          const local = localVersion.split('\.');
          const cur = currentVersion.split('\.');
          if (local[0] == cur[0] && local[1] == cur[1]) {
            console.log('現在は最新のバージョンです');
          } else {
            LocalDataService.setVersion(current.slice(1));
            window.location.reload(true);
          }
        } else {
          LocalDataService.setVersion(current.slice(1));
          window.location.reload(true);
        }
      }

      // 認証チェック
      await auth0Module.initClient();
      const isAuthenticated = await auth0Module.isAuthenticated();
      if (isAuthenticated) {
        this.me = await meModule.fetchMe();
        this.isToFirstStep();
      } else {
        try {
          auth0Module.getTokenSilently();
          // console.log('OK getTokenSilently:', response);
          this.me = await meModule.fetchMe();
          this.isToFirstStep();
        } catch (error) {
          // console.log('error catch getTokenSilently:', error);
          if (this.$route.query && this.$route.query.code && this.$route.query.state) {
            await auth0Module.handleAuth0();
          }
          console.log('再度サインインプロセスへ');
          await auth0Module.signinProcess();
        }
      }
    } catch (error) {
      switch (error) {
        case ErrorType.FIRSTLOGIN:
          try {
            await meModule.postMe();
            await auth0Module.signinProcess();
          } catch {
            console.log('エラーのためサインインプロセスへ', error);
            await auth0Module.signinProcess();
          }
      }
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async isToFirstStep() {
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (user.nurserySchools.length === 0) {
          this.$router.push('/signup');
        }
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const currentUserId = `${nurserySchool.userId}`;
        const token = await auth0Module.getAuth0Token();
        if (currentUserId && token) {
          console.log('set auth');
          LocalDataService.setPushAuthorizer(`${token},${currentUserId}`);
        }
      }
      if (this.me && this.me.nurserySchools.length > 0) {
        pusherModule.subscribe();
        this.loading = false;
        if (this.routePath === '/signin' || this.routePath === '/signup') {
          this.$router.push('/', () => {});
        }
      } else {
        this.loading = false;
        this.$router.push('/signup');
      }
    },
  },
});
