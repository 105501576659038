import { GetCurrentVersion } from './../model/Response';
import ApiClient from '@/api/ApiClient';
import {
  MeResponse,
} from 'chaild-api/lib/index';
import {
  PostMeRequest, PutMeRequest,
} from '@/model/Request';

export default class ApiMe {

  public static async fetchMe(): Promise<MeResponse> {
    try {
      const response = await ApiClient.get(`/me`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async postMe(): Promise<MeResponse> {
    try {
      const response = await ApiClient.post(`/me`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async putMe(input: PutMeRequest): Promise<MeResponse> {
    try {
      const response = await ApiClient.put(`/me`, input);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async fetchVersion(): Promise<GetCurrentVersion> {
    try {
      const response = await ApiClient.get(`/current-version`, null);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
