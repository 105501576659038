import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import ApiAttendance from '@/api/ApiAttendance';

import {
  NurseryAttendanceResponse,
} from 'chaild-api/lib/index';
import {
  GetAttendanceSearchRequest,
  GetAttendanceRequest,
  PostAttendanceRequest,
  PostAttendanceAttendRequest,
  PostAttendanceLeaveRequest,
  ChangeAttendanceRequest
} from '@/model/Request';
import {
  GetAttendanceSearchResponse,
  MessageResponse
} from '@/model/Response';

export interface AttendanceState {
  attendances: GetAttendanceSearchResponse | null;
  childAttendance: NurseryAttendanceResponse | null;
  attendancesByClass: GetAttendanceSearchResponse[];
}

@Module({ dynamic: true, store, name: 'attendance', namespaced: true })
class AttendanceModule extends VuexModule implements AttendanceState {

  public attendances: GetAttendanceSearchResponse | null = null;
  public childAttendance: NurseryAttendanceResponse | null = null;
  public attendancesByClass: GetAttendanceSearchResponse[] = [];

  @Action({ rawError: true })
  public async searchAttendance(input: GetAttendanceSearchRequest) {
    try {
      const response = await ApiAttendance.searchAttendance(input);
      this.setAttendances(response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAttendances(attendances: GetAttendanceSearchResponse) {
    this.attendances = attendances;
  }

  @Action({ rawError: true })
  public async searchAttendanceByClass(input: GetAttendanceSearchRequest) {
    try {
      const response = await ApiAttendance.searchAttendance(input);
      this.setAttendancesByClass(response);
      return response
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setAttendancesByClass(attendancesByClass: GetAttendanceSearchResponse) {
    this.attendancesByClass.push(attendancesByClass)
  }

  @Mutation
  public resetAttendancesByClass() {
    this.attendancesByClass = []
  }

  @Action({ rawError: true })
  public async getAttendance(input: GetAttendanceRequest) {
    try {
      const response = await ApiAttendance.getAttendance(input);
      this.setChildAttendance(response);
      return true;
    } catch (error) {
      throw error;
    }
  }

  @Mutation
  public setChildAttendance(childAttendance: NurseryAttendanceResponse) {
    this.childAttendance = childAttendance;
  }

  @Action({ rawError: true })
  public async postAttendance(input: PostAttendanceRequest) {
    try {
      const response = await ApiAttendance.postAttendance(input);
      return response;
    } catch (error) {
      throw error;
    }
  }

}

export const attendanceModule = getModule(AttendanceModule);
