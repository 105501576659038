







import Vue from 'vue';
import ChildRecordsContainer from '@/container/records/ChildRecordsContainer.vue';

export default Vue.extend({
  components: {
    ChildRecordsContainer,
  },
});
