
























































































































import Vue from 'vue';

import DatePager from '@/components/molecules/DatePager.vue';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import VueLoading from '@/components/pages/Loding.vue';
import LocalDataService from '@/service/LocalDataService';
import { nurseryModule } from '@/store/dataModules/nurseryModule';
import { childrenModule } from '@/store/dataModules/childrenModule';
import { diaryReportModule } from '@/store/dataModules/diaryReportModule';
import {
  DailyReportResponse,
  DefecationStatusType,
  DiaryResponse,
  NurseryChildResponse,
  NurseryClassResponse,
} from 'chaild-api/lib';
dayjs.locale(ja);
export default Vue.extend({
  components: {
    DatePager,
    VueLoading,
  },
  data: () => {
    return {
      fetchLoading: false,
      date: '' as any,
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
      classId: 0,
      childId: 0,
    };
  },
  computed: {
    clas(): NurseryClassResponse | null {
      if (nurseryModule.clas) {
        return nurseryModule.clas;
      }
      return null;
    },
    child(): NurseryChildResponse | null {
      if (childrenModule.child) {
        return childrenModule.child;
      }
      return null;
    },
    childReport() {
      if (diaryReportModule.childReport.length > 0) {
        return diaryReportModule.childReport[0].home;
      }
      return [];
    },
  },
  watch: {
    date: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    addDay() {
      this.date = this.date.add(1, 'd');
    },
    subtractDay() {
      this.date = this.date.subtract(1, 'd');
    },
    formatTime(time: string) {
      const nowTime = dayjs(time).format('HH:mm');
      return nowTime;
    },
    showDefecationStatus(state: DefecationStatusType) {
      if (state === 'hard') {
        return '硬い';
      } else if (state === 'normal') {
        return '普通';
      } else if (state === 'soft') {
        return '柔らかい';
      }
    },
    showMilkCategory(category: string) {
      if(category === 'breast') {
        return '母乳'
      } else if(category === 'formula') {
        return 'ミルク'
      }
    },
    toClassDiary() {
      const classId = this.child?.nurseryClasses[0].classId;
      if (classId) {
        this.$router.push(`/record/class/${classId}`);
      }
    },
    init: async function() {
      try {
        this.fetchLoading = true;
        const user = LocalDataService.getUser();
        const classId = this.$route.params.classId;
        const childId = this.$route.params.childId;
        this.classId = parseInt(classId, 10);
        this.childId = parseInt(childId, 10);
        if (user) {
          let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
          if (!nurserySchool) {
            nurserySchool = user.nurserySchools[0];
          }
          const nurseryId = nurserySchool.nurseryId;
          Promise.all([
            nurseryModule.getNurseryClassDetail({
              nurseryId,
              classId: this.classId,
            }),
            childrenModule.getChild(this.childId),
          ]);
          await diaryReportModule.fetchChildReport({
            childId: this.childId,
            from: this.date.format('YYYY-MM-DD'),
            to: this.date.format('YYYY-MM-DD'),
          });
        }
      } catch {
      } finally {
        this.fetchLoading = false;
      }
    },
  },
  async created() {
    const date = this.$route.query.date as string
    if (date) {
      const temp = dayjs(date);
      this.date = temp
    } else {
      this.date = dayjs();
    }
  },
  async mounted() {
    this.init();
  },
  beforeDestroy() {
    childrenModule.refreshChild();
    nurseryModule.refreshClass();
  },
});
